.social {
  padding: 0;
  margin-top: rem(12);
  list-style: none;

  @include media-breakpoint-up(md) {
    margin-top: 0;
  }

  &-link {
    position: relative;
    padding-left: rem(32);
    color: $uks-color-text;

    &::before {
      position: absolute;
      top: rem(-1);
      left: 0;
      width: rem(24);
      height: rem(18);
      content: "";
      background: no-repeat 50% 50%;
      background-size: contain;
      speak: none;
    }

    &-facebook::before {
      background-image: $uks-svg-facebook;
    }

    &-youtube::before {
      top: rem(1);
      background-image: $uks-svg-youtube;
    }

    &-instagram::before {
      top: rem(1);
      background-image: $uks-svg-instagram;
    }

    &-twitter::before {
      top: rem(1);
      background-image: $uks-svg-twitter;
    }

  }
}

.social-share {
  display: inline-block;
  margin: 20px 0;

  @include media-breakpoint-up(md) {
    .icon-whatsapp {
      //display: none;
    }
  }

  a:focus {
    outline: none;
  }

  .icon-copy {
    position: relative;

    .copy-feedback {
      position: absolute;
      top: 40px;
      right: 0;
      display: none;
      width: 200px;
      padding: 3px;
      background: $uks-color-tones-7;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25);
    }
  }

  .social-share-title {
    float: left;
    margin: 5px 20px 0 0;
    font-weight: 700;
  }

  .social-share-content {
    float: left;

    .social-share-icon {
      float: left;
      margin-right: 20px;
    }
  }

  svg:hover {
    .icon {
      fill: $uks-color-text;
    }
  }
}

.tx-pb-social {
  display: inline-block;

  .column {
    width: calc(33% - 20px);
    margin: 0 10px 0 10px;

    @include media-breakpoint-down(md) {
      float: none;
      width: 100%;
    }
  }

  .pb-list-item {
    margin-bottom: 15px;
    border: 1px solid $uks-color-primary;

    img {
      width: 100%;
      height: auto;
      margin-bottom: 15px;
    }

    a {
      color: $uks-color-text;
    }

    .header {
      display: inline-block;
      padding: 5px;
      clear: both;

      .header-icon {
        float: left;
        margin: 10px 5px 0 0;
      }

      .header-content {
        float: left;
      }

      .twitter-title {
        width: 100%;
        font-size: 17px;
        font-weight: 700;
      }

      .twitter-user {
        width: 100%;
        font-size: 15px;
        color: $uks-color-text-light;
      }
    }

    .text {
      padding: 15px;
    }
  }
}
