.subnavigation {
  font-family: $font-family-alt-medium;

  @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
    .page--sticky-subnav & {
      position: sticky;
      top: 20px;
    }
  }

  &-wrapper {
    display: none;
    margin-top: rem(6);

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      display: block;
    }
  }

  &-wrapper-faculty {
    display: block;
    margin-top: rem(-10);

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      margin-top: rem(10);
    }
  }

  .back-link + & {
    margin-top: rem(8);
  }

  &-list {
    display: none;
    padding: 0;
    margin: 0;
    font-size: rem(17);
    line-height: (20 / 17);
    list-style: none;
    border-left: 1px solid $uks-color-tones-10;

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      display: block;
    }

    &--faculty {
      display: block;
      margin-bottom: $uks-component-margin;
      opacity: 1;
      transition: opacity $uks-duration-long ease;

      // Small-screen-specific behaviours complex so use a down to avoid excessive overrides
      @include respond-up-to($uks-screen-fullnav, 1px) {
        height: 0;
        overflow: hidden;
        border: 0;
        opacity: 0;

        &.js-active {
          height: auto;
          padding: rem(12) 0 rem(20);
          overflow: visible;
          border: 1px solid $uks-color-tones-10;
          border-top: 0;
          opacity: 1;
        }
      }

      // Workaround caused by mis-use of entrylevel on restricted_subnav pages
      .subnavigation-list--nested {
        display: block;
      }
    }
  }

  &-item {
    margin-bottom: rem(24);

    .subnavigation-sublist & {
      margin-bottom: rem(12);
    }

    &:last-child {
      margin: 0;
    }
  }

  &-sublist {
    padding: rem(12) 0 0;
    margin: 0;
    font-size: rem(13);
    line-height: (16 / 13);
    list-style: none;

    //.subnavigation-item {
    //    @include hyphens;
    //}
  }

  &-link {
    position: relative;
    display: block;
    padding: rem(4) rem(24) rem(4) rem(12);
    color: $uks-color-text;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      display: none;
      width: 1px;
      content: "";
    }

    &.subnavigation-current,
    &:focus,
    &:hover {
      color: $uks-color-primary;
      text-decoration: underline;

      &::before {
        display: block;
        background: $uks-color-primary;
      }
    }

    .subnavigation-sublist & {
      padding: 0 rem(24) 0 rem(12);
    }

    .subnavigation-sublist-sub & {
      padding-left: rem(24);
    }
  }

  &-header {
    padding: rem(10) rem(24) rem(8);
    margin: 0;
    font-family: $font-family;
    font-size: 1rem;
    font-weight: 700;
    color: $white;
    background: $uks-color-text-light;
    border-top: 6px solid $uks-color-primary;
  }

  &-toggle {
    @include text-button;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: rem(33);
    padding: 0 rem(8);
    background: $uks-color-primary;
    border-left: 1px solid $white;

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      display: none;
    }

    &:focus {
      outline: 1px dotted $white;
      outline-offset: -1px;
    }

    &::after {
      display: block;
      height: 100%;
      content: "";
      background: $uks-svg-link-internal-white no-repeat 50% 50%;
      background-size: auto rem(14);
      transition: transform $uks-duration-short ease;
      transform: rotate(90deg);
    }

    &[aria-expanded="true"]::after {
      transform: rotate(-90deg);
    }

    &--back {
      right: auto;
      left: 0;
      border-right: 1px solid $white;
      border-left: 0;

      &::after {
        transform: rotate(180deg);
      }
    }
  }
}
