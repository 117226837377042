.news {
  &-list {
    padding: 0;
    color: $uks-color-text;
    list-style: none;

    a:not(.btn) {
      color: inherit;
    }

    .news-list-teaser-colored & {
      // Meta also black, not just links
      color: $black;
    }

    .image-wrapper {
      margin: 0 rem(20) rem(10) 0;
    }

    .media {
      // Overriding Bootstrap defaults, so use a breakpoint-down
      @include media-breakpoint-down(xs) {
        display: block;
      }
    }

    .news-tags {
      margin-top: .75rem;
    }

  }

  &-list-teaser {
    padding: $uks-teaser-spacer-top $uks-teaser-spacer-right-reduced $uks-teaser-spacer-bottom $uks-teaser-spacer-left-reduced;
    background: $white;
    border: 1px solid $uks-color-border;

    &-green {
      background: $uks-color-secondary;
      border-color: $uks-color-secondary;
    }

    &-teal {
      background: $uks-color-tones-11;
      border-color: $uks-color-tones-11;
    }

    &-blue {
      background: $uks-color-tones-13;
      border-color: $uks-color-tones-13;
    }
  }

  &-list-item {
    @include clearfix;
    padding-bottom: rem(10);
    margin-bottom: rem(20);
    border-bottom: 1px solid $table-border-color;

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    .news-list-teaser & {
      padding-bottom: rem(10);
      margin-bottom: rem(12);

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }

    .news-list-teaser-colored & {
      border-color: $uks-color-border-neutral;
    }
  }

  &-meta {
    display: block;
    margin-bottom: rem(4);
    font-family: $font-family;
    font-size: rem(13);
    font-weight: 700;
    line-height: (20 / 13);
    text-transform: uppercase;
    //color: $uks-color-text-light;

    .event-list-item & {
      font-size: rem(20);
      font-weight: 700;
      line-height: (24 / 20);
    }
  }

  &-cat {
    color: $uks-color-tones-9;

    .card-teaser-colored &,
    .news-list-teaser-colored & {
      color: inherit;
    }

    .card-date-small & {
      font-family: $font-family;
      font-size: rem(13);
      font-weight: 700;
      text-transform: uppercase;
    }

    .event-list-item & {
      display: block;
      margin: rem(5) 0 rem(6);
      font-size: rem(13);
      font-weight: 700;
      line-height: (20 / 13);
    }
  }

  &-list-title {
    margin-bottom: rem(15);
    font-size: rem(20);
    font-weight: 400;
    line-height: (24 / 20);
  }

  &-list-title-small {
    font-family: $font-family-alt-medium;
  }

  &-tags {
    font-size: 15px;
    color: $uks-color-tones-5;
  }

  &-detail {
    .news-tags {
      margin-bottom: .75rem;
    }
  }
}
