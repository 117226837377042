.cookie-notice {
  background-color: $uks-color-tones-7;

  &.js-disabled {
    display: none;
  }

  &__liner {
    display: flex;
    padding: rem(14) ($grid-gutter-width / 2) rem(18);
  }

  &__text {
    flex: 1 1 auto;
    padding-right: rem(20);
    margin: 0;
  }

  &__accept {
    margin: auto 0;
    border: 1px solid $black;
  }

  &__accept-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
  }
}
