.meta-list {
  padding: 0;
  list-style: none;

  &-title {
    padding-right: rem(5);
  }

  * + [class^="link-"] {
    margin-top: rem(10);
  }

  @include media-breakpoint-up(ukssm, $uks-altgrid-breakpoints) {
    &-item {
      @include clearfix();
      display: flex;
    }

    &-title {
      float: left;
      width: rem(150);
    }

    &-content {
      flex: 1;
      float: left;
    }
  }
}
