.wall {
  &__box {
    @include opacity-transition;
    opacity: 1;

    @include media-breakpoint-up(md) {
      @include clearfix;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      margin-right: -$uks-newswall-gutter / 2;
      margin-left: -$uks-newswall-gutter / 2;
    }

    &.js-updating {
      opacity: .5;
    }
  }

  &__loading-wrapper {
    @include opacity-transition;
    position: absolute;
    z-index: -1;
    width: 100px;
    height: 0;
    overflow: hidden;
    opacity: 0;
    speak: none;

    &.js-updating {
      position: static;
      z-index: 10;
      height: 100px;
      margin: rem(20) auto 0;
      opacity: 1;

    }
  }

  &__footer {
    margin-top: $uks-component-margin;
    text-align: center;
  }

  &__item {
    @include media-breakpoint-up(md) {
      box-sizing: border-box;
      display: flex;
      // fallback
      float: left;
      width: 50%;
      padding-right: $uks-newswall-gutter / 2;
      padding-left: $uks-newswall-gutter / 2;
      margin-bottom: $uks-newswall-gutter;

      &--medium,
      &--large {
        width: 100%;
      }

      > * {
        // Flexing, so make sure it's full width
        width: 100%;
      }
    }

    // Flexbox sizes
    @include media-breakpoint-up(lg) {
      width: 25%;

      &--medium-small {
        width: 1 / 3 * 100%;
      }

      &--medium {
        width: 50%;
      }

      &--medium-large {
        width: 2 / 3 * 100%;
      }

      &--medium-largest {
        width: 75%;
      }

      &--large {
        width: 100%;
      }

    }

    // Workaround for when wall__item is an LI and inherits default bootstrap list styles
    &:last-child {
      margin-bottom: $uks-component-margin;

      @include media-breakpoint-up(lg) {
        margin-bottom: $uks-newswall-gutter;
      }
    }

    // Stretch any news plugins to full height, if they have no headline
    .news:first-child {
      height: 100%;
    }

  }

  &__link {
    margin: $uks-newswall-gutter $uks-newswall-gutter / 2 0;
  }

}
