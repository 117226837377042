.quick-start {

  @include media-breakpoint-up(sm) {
    + .quick-start {
      margin-left: rem(-18);
    }
  }

  &-control {
    @include text-button;
    padding: rem(10);
    margin: 0;
    font-size: rem(13);
    line-height: (20 / 13);
    letter-spacing: $uks-letter-spacing-reduced;

    @include media-breakpoint-up(sm) {
      padding: rem(10) rem(20);

      &--lang {
        padding-right: rem(4);
        padding-left: rem(8);
      }
    }

    &--lang {
      padding-right: rem(4);
      padding-left: rem(8);
    }

    &.js-active {
      color: $link-color;

      &:hover {
        color: $link-hover-color;
      }

    }

    &:focus {
      color: $link-hover-color;
      text-decoration: underline;
      outline: 1px dotted $black;
    }
  }

  &-dropdown {
    @include opacity-transition;
    position: absolute;
    right: 0;
    left: 0;
    height: 0;
    padding: 0 rem(20);
    margin: 0;
    overflow: hidden;
    font-family: $font-family-alt-medium;
    color: $white;
    text-align: right;
    // Inherits this already, but prevent breakage if parent value changes
    background: rgba($uks-color-primary, .95);
    opacity: 0;

    &.js-active,
    &:not(.js-initialised):target {
      z-index: 11;
      height: auto;
      padding-top: rem(10);
      padding-bottom: rem(10);
      opacity: 1;
    }

    li {
      padding-right: rem(20);
      margin: 0;
    }

    // Not ideal, but avoids duplication of styles
    .navigation-close {
      top: rem(10);
      right: rem(10);

      @include media-breakpoint-up(sm) {
        right: rem(20);
      }
    }
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style: none;

    &--lang {
      body:not([data-faculty]) & {
        li {
          padding-right: rem(36);
        }
      }
    }

  }

  &-link {
    display: inline-block;
    margin-bottom: rem(5);
    font-size: rem(17);
    line-height: (24 / 17);
    color: $white;

    &:hover,
    &:focus {
      color: $white;
    }
  }

}
