.back-link {
  position: relative;
  display: inline-block;
  max-width: 100%;
  padding-left: rem(26);
  margin-bottom: rem(40);
  color: $uks-color-text;

  &::before {
    position: absolute;
    top: rem(4);
    left: 0;
    width: rem(13);
    height: rem(14);
    content: "";
    background: $uks-svg-arrow-thick no-repeat 50% 50%;
    background-size: contain;
    transform: rotate(180deg);
  }

  &-faculties {
    margin-bottom: rem(5);
    font-size: rem(17);
    line-height: 1;
    text-transform: uppercase;
    transform: translateY(rem(-7));

    &::before {
      top: rem(2);
    }
  }

  &--blog,
  &--news {
    margin-bottom: rem(17);

    [data-faculty] & {
      @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
        margin-top: rem(7);
      }
    }
  }

  &--js {
    pointer-events: none;
    opacity: .5;
  }

  &-wrapper {
    text-align: right;
  }
}
