.faculty {
  &-title-box {
    position: relative;
    padding: rem(4) rem(40) rem(4) 0;
    margin-bottom: 0;
    color: $white;
    background: $uks-color-text-light;

    &__link {
      color: $white;

      &:hover {
        color: $white;
      }

      &:focus {
        outline: 1px dotted $white;
      }
    }

    &__text {
      display: inline-block;
      max-width: 100%;
      padding-left: rem(40);

      // No back button
      &:first-child {
        padding-left: rem(13);
      }
    }

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      padding-right: rem(12);
      padding-left: rem(13);
      margin-bottom: rem(15);

      &__text {
        padding-left: 0;
        // No back button
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  &-parent-box {
    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      display: none;
    }

    &__link {
      margin-bottom: 0;
    }
  }

  &-topnav {
    margin-bottom: rem(10);
    font-size: rem(16);
    text-transform: uppercase;

    &-list {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &-item {
      margin-bottom: rem(10);
    }

    &-link {
      display: block;
      padding: rem(4) rem(12);
      color: $uks-color-text;
      background: $uks-color-tones-2;
    }
  }

  &-sidebar-logo {
    display: none;
    padding: 0 rem(12);

    &-default {
      display: none;
      margin-top: $uks-component-margin;

      @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
        display: block;
      }
    }

    &-footer {
      display: block;
      width: 100%;
      max-width: 302px;
      margin-bottom: rem(10);

      @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
        display: none;
      }
    }
  }

  &-sidebar-logo-img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}
