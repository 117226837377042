.skip-to {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);

  &-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &-item {
    padding: 0;
    margin: 0;
  }

  &-link {
    // Bootstrap mixins:
    @include sr-only;
    @include sr-only-focusable;
    display: inline-block;
    padding: rem(16) rem(20) rem(20);
    font-weight: 700;
    color: $uks-color-text;
    background: $white;
    border: 1px solid $uks-color-border;
    border-top: 0;
  }
}
