.sponsor {
  @include clearfix;
  margin: $uks-component-margin 0;
  font-size: rem(13);

  &-list {
    padding: 0;
    margin: 0 -15px;
    list-style: none;
  }

  &-item {
    position: relative;
    display: inline-block;
    padding: 0 15px 15px;
    vertical-align: top;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: flex-start;

    &-intro {
      float: left;
      width: 24.25%;
      padding-right: rem(28);
    }

    &-list {
      float: left;
      width: calc(75.75% + 30px);
    }

    &-item {
      width: (100% / 6);
    }
  }
}
