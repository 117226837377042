*:focus {
  outline: 1px dotted $black;
}

button:focus {
  outline: 1px dotted $black;
}

body {
  background-color: $white;
  /* stylelint-disable selector-no-qualifying-type  */
  &.start-page {
    background-color: $uks-color-page;
  }
  /* stylelint-enable  */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

h1,
.h1 {
  //@include hyphens;
  margin-bottom: rem(32);
  font-weight: 400;
  line-height: (48 / 40);
}

//h2, .h2 {
//    margin-bottom: rem(20);
//    line-height: (30 / 24);
//    font-weight: 700;
//}

h2,
.h2 {
  margin-bottom: rem(16);
  font-weight: 700;
  line-height: (24 / 20);

  //@include media-breakpoint-down(xs) {
  //    @include hyphens;
  //}
}

h3,
.h3,
h4,
.h4 {
  font-weight: 700;
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-bottom: rem(4);
  line-height: (24 / 16);
}

li {
  margin-bottom: rem(10);

  &:last-child {
    margin-bottom: 0;
  }

  ol,
  ul {
    margin: rem(10) 0 rem(12);
  }
}

/* stylelint-disable selector-max-type  */
ul {
  list-style-type: disc;

  ul {
    list-style-type: circle;

    ul {
      list-style-type: square;
    }
  }
}
/* stylelint-enable  */

cite {
  font-style: normal;
}

legend {
  font-family: $font-family-alt;
}

label {
  margin-bottom: rem(5);
  font-family: $font-family-alt;
  font-size: rem(14);
  font-weight: 700;
  line-height: (22 / 14);
}

option {
  line-height: 30px;
  /* stylelint-disable property-no-vendor-prefix */
  -moz-padding-end: $input-padding-x;
  -moz-padding-start: $input-padding-x;
  /* stylelint-enable */
}

figure {
  margin-bottom: $uks-component-margin-reduced;

  &:last-child {
    margin-bottom: 0;
  }
}

caption,
figcaption {
  margin-top: rem(10);
  font-size: $figure-caption-font-size;
  line-height: (22 / 14);
}

caption {
  // Bootstrap overwrite, no alternative
  padding: 0;
  color: $uks-color-text;
}

th {
  // If table from RTE, .content-text wrapping would make color:#000. Needs to be grey (whether RTE or default tables).
  color: $uks-color-text;
}
