// Search form is a one-off element, so skip normal Bootstrap structure/handling
.search {
  &-form-wrapper {
    display: inline-block;
    margin: rem(10) 0 0;
    vertical-align: top;
  }

  &-form-control {
    display: block;
    width: rem(20);
    height: rem(20);
    padding: rem(1);
    margin-bottom: rem(8);
    cursor: pointer;
    background: none;
    border: 0;

    &-icon,
    &-icon-close {
      display: block;
      width: rem(18);
      height: rem(18);
    }

    &-icon-close {
      display: none;
      transform: rotate(45deg);
    }

    &.js-active {
      .search-form-control-icon {
        display: none;
      }

      .search-form-control-icon-close {
        display: block;
      }
    }
  }

  &-form {
    @include opacity-transition;
    position: absolute;
    right: 0;
    left: 0;
    z-index: 11;
    height: 0;
    overflow: hidden;
    background: rgba($uks-color-primary, .95);
    opacity: 0;

    &:not(.js-initialised):target,
    &.js-active {
      height: auto;
      // Leave as px - logo sized in px, page gutter sized in px - relative to those
      padding: 18px 0;
      opacity: 1;

      @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
        padding: 5px 0;
      }
    }
  }

  &-form-liner {
    @include media-breakpoint-up(lg) {
      padding: 0 20%;
    }

    &--full {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: rem(10);

      @include media-breakpoint-up(ukssmmid, $uks-altgrid-breakpoints) {
        flex-wrap: nowrap;
      }

      .form-control--search-select {
        flex: 0 0 auto;
        width: auto;

        &:focus {
          outline: rem(1) dotted $white;
          outline-offset: 2px;
        }
      }

      .search-submit {
        flex: 0 0 auto;
      }
    }
  }

  &-field {
    display: inline-block;
    width: calc(100% - #{rem(48)});
    padding: rem(2) 0 rem(1) rem(2);
    margin-right: rem(12);
    font-size: rem(30);
    color: $white;
    vertical-align: middle;
    background: transparent;
    border: rem(1) solid $white;

    .search-form-liner--full & {
      flex: 1 1 auto;
      width: 100%;
      min-width: rem(200);
      margin-right: 0;

      @include media-breakpoint-up(ukssmmid, $uks-altgrid-breakpoints) {
        // Force small start - flexes anyway
        width: 10%;
      }
    }

    &:focus {
      outline: 1px dotted $white;
      outline-offset: 2px;
    }

    /* stylelint-disable selector-no-vendor-prefix  */
    &::-webkit-input-placeholder {
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      opacity: .75;
    }

    &::-moz-placeholder {
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      opacity: .75;
    }

    &:-ms-input-placeholder {
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      opacity: .75;
    }
    /* stylelint-enable */
  }

  &-submit {
    display: inline-block;
    width: rem(32);
    height: rem(32);
    padding: 0;
    vertical-align: middle;
    cursor: pointer;
    background: transparent;
    border: 0;

    &:focus {
      outline: 1px dotted $white;
    }

    &-icon {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__active-filters-list {
    display: flex;
    flex-wrap: wrap;
    gap: 0 18px;
    padding: 0;
    list-style: none;
  }

  &__active-filters-item {
    padding: 0;
    margin-top: 0;
  }

  &__summary-dt,
  &__summary-dd {
    display: inline;
  }
}
