// UKS-specific: needed for copyright positioning (only)
.image-wrapper {
  position: relative;
  display: inline-block;
  max-width: 100%;
  line-height: 1;

  .gallery & {
    display: block;
  }
}

.image-copyright {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 100%;
  padding: rem(2) rem(6) rem(3);
  font-size: rem(8);
  line-height: (10 / 8);
  color: $uks-color-text;
  background: $uks-color-tones-8;

  &:empty {
    display: none;
  }

  .js-clickenlarge & {
    z-index: 2;
  }

  // Not supported by any IE, but cleanest method. Using rotate transforms only produces blurry text and makes max-height impossible to accurately guess (element rotated so width is now vertical). IE displays horizontal text.
  // Multiple workarounds for different levels of support
  @supports (writing-mode: sideways-lr) {
    writing-mode: sideways-lr;
    max-width: none;
    max-height: 100%;
    padding: rem(6) rem(3) rem(6) rem(2);
  }

  @supports (writing-mode: vertical-lr) and (not (writing-mode: sideways-lr)) {
    writing-mode: vertical-lr;
    max-width: none;
    max-height: 100%;
    padding: rem(6) rem(2) rem(6) rem(3);
    font-size: rem(9);
    line-height: (10 / 9);
    transform: rotate(180deg);
  }

  .news-list-item & {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;

    // General reset for browsers given writing-mode workaround
    @supports (writing-mode: vertical-lr) {
      writing-mode: horizontal-tb;
      max-width: 100%;
      max-height: none;
      padding: rem(2) rem(6) rem(3);
      font-size: rem(8);
      line-height: (10 / 8);
      transform: none;
    }
  }
}

// Overwrite Bootstrap - no vars available
.figure {
  .gallery & {
    display: block;
  }
}

.figure-img {
  margin-bottom: 0;

  .gallery & {
    display: block;
  }
}

.figure-img__link {
  display: block;
}

.js-clickenlarge {
  position: relative;

  &::after {
    position: absolute;
    right: 6px;
    bottom: 6px;
    width: rem(27);
    height: rem(27);
    content: "";
    background: $uks-svg-enlarge no-repeat 50% 50%;
    background-size: 100% auto;
  }
}
