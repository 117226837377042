.navigation {
  display: none;

  @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
    display: block;
  }

  &-row-raised {
    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      margin-top: rem(-8);
    }
  }

  &-col {
    position: static;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &-list-item {
    margin: 0 0 0 rem(23);

    &:first-child {
      margin-left: 0;
    }
  }

  // Top level page with tx_ukconfig_hide_in_main_menu checked. Safety fallback. TS limitations cannot prevent subpage links rendering. Element should be removed from DOM by JS
  &-list-item-disabled {
    display: none;
  }

  &-section-headline {
    margin: 0;
  }

  &-link {
    position: relative;
    display: block;
    padding: rem(8) 0;
    font-family: $font-family-alt-medium;
    font-size: rem(17);
    line-height: (24 / 17);
    // Reinforce - also a <button>
    color: $uks-color-text;
    text-transform: uppercase;
    letter-spacing: $uks-letter-spacing;
    cursor: pointer;
    background: transparent;
    border: solid transparent;
    border-width: 0 1px;
    outline-offset: -1px;

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 1px;
      content: "";
    }

    &:focus,
    &:hover {
      color: $uks-color-text;
      text-decoration: none;

      &::before {
        background-color: $uks-color-nav;

        .navigation--intranet & {
          background-color: $uks-color-tones-13-dark;
        }
      }
    }

    // Have to have this - impossible to see focus on a --current link with closed menu
    &:focus {
      outline: 1px dotted $uks-color-tones-6;
    }

    &.js-active {
      color: $uks-color-nav-current;

      .navigation--intranet & {
        color: $uks-color-tones-13-dark;
      }

      &:focus {
        color: $uks-color-nav-current;

        .navigation--intranet & {
          color: $uks-color-tones-13-dark;
        }
      }

      &::before {
        background-color: $uks-color-nav-current;

        .navigation--intranet & {
          background-color: $uks-color-tones-13-dark;
        }
      }

      .navigation-link-liner::after {
        display: block;
      }
    }

    &--current {
      &::before {
        background-color: $uks-color-nav-current;

        .navigation--intranet & {
          background-color: $uks-color-tones-13-dark;
        }
      }
    }
  }

  // Link only used as a fallback if a top-level page has no subpages - keep visible until JS initialized, then functionality moved to main button so can hide it
  &-toplevel-link {
    color: $white;

    .js-initialized & {
      display: none;
    }
  }

  &-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 1;
    height: 0;
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;
    font-family: $font-family-alt-medium;
    background: $uks-color-primary;
    opacity: 0;
    transition: opacity $uks-duration-long ease;

    *[data-multicolumn] + & {
      padding-right: rem(15);
      padding-left: rem(15);

      .navigation-sub-nav-wrapper {
        max-width: 1110px;
        margin-right: auto;
        margin-left: auto;
        column-count: 4;
        column-gap: rem(30);
      }

      .navigation-sub-nav {
        page-break-inside: avoid;
        break-inside: avoid;
      }
    }

    .navigation--intranet & {
      color: $black;
      background: $uks-color-tones-13;
    }

    &.js-active,
    .navigation:not(.js-initialized) &:target {
      height: auto;
      padding-top: rem(30);
      opacity: 1;
    }

    .navigation-list-item-last & {
      text-align: right;
    }

    /* stylelint-disable declaration-no-important  */
    // JS uses min-height/padding as inline style as requires calculated values - ensure these are ignore when the dropdown is closed to avoid any overlaying issues
    .js-initialized &:not(.js-active) {
      min-height: 0 !important;
      padding: 0 !important;
    }
    /* stylelint-enable */
  }

  &-close {
    position: absolute;
    // Leave as px
    top: 20px;
    right: 20px;
    width: rem(24);
    height: rem(24);
    padding: 0;
    cursor: pointer;
    background: none;
    border: 0;

    &:focus {
      outline: 1px dotted $white;
    }

    &::after {
      position: absolute;
      top: rem(4);
      left: rem(4);
      width: rem(16);
      height: rem(16);
      content: "";
      background: transparent $uks-svg-plus-white no-repeat 50% 50%;
      background-size: 100% auto;
      transform: rotate(45deg);
    }
  }

  &-sub-nav {
    //padding: 0 rem(8);
    padding: 0;
    margin-bottom: rem(24);
  }

  &-sub-nav-list {
    padding: 0;
    margin-top: 0;
    list-style: none;
  }

  &-sub-nav-header {
    margin-bottom: rem(5);
    font-size: rem(24);
    line-height: (29 / 24);
  }

  &-sub-nav-item {
    margin-bottom: rem(5);
    font-size: rem(17);
    line-height: (24 / 17);
  }

  &-item-link,
  &-sub-nav-link,
  &-sub-nav-header-link {
    color: $white;

    .navigation--intranet & {
      color: $black;
    }

    &:focus,
    &:hover {
      color: $white;

      .navigation--intranet & {
        color: $black;
      }
    }
  }

  &-sub-nav-link,
  &-sub-nav-header-link {
    position: relative;
    display: inline-block;

    &--current {
      text-decoration: underline;
    }
  }

}

// Meta / quick-start nav
.navigation-meta {
  display: inline-block;
  margin-left: rem(38);
  font-family: $font-family-alt;

  &-list {
    padding-left: 0;
    margin: 0;
    list-style: none;
  }

  &-item {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: top;

    @include media-breakpoint-up(sm) {
      &:not(:last-child) {
        margin-right: rem(18);
      }
    }
  }

  &-link {
    display: inline-block;
    margin: rem(10) 0;
    // Reinforce - also a <button>
    font-family: $font-family-alt-medium;
    font-size: rem(13);
    line-height: (20 / 13);
    color: $uks-color-text;
    text-transform: uppercase;
    letter-spacing: $uks-letter-spacing;
  }

  &-icon {
    display: inline-block;
    vertical-align: middle;
    transform: translateY(rem(-1));

    &--right {
      margin-left: rem(6);
      transition: transform .25s;

      [aria-expanded="true"] & {
        transform: translateY(rem(-1)) rotate(180deg);
      }
    }

    &--left {
      margin-right: rem(6);
      transform: translateY(rem(-2));
    }
  }

  &-link-abbr {
    font-size: rem(14);
    line-height: (20 / 14);
  }

  &-link-icon {
    position: relative;
  }

  &-link-lang {
    .quick-start + .navigation-meta-item & {
      @include media-breakpoint-up(sm) {
        margin-left: rem(-18);
      }
    }
  }
}

.navigation-mobile {
  @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
    display: none;
  }

  &-control {
    position: absolute;
    top: rem(-8);
    // Leave all as px - no space to expand
    right: -10px;
    z-index: 10;
    width: 50px;
    height: 50px;
    padding: 10px;
    overflow: hidden;
    color: $uks-color-primary;
    background: transparent;

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      display: none;
    }

    &-opened {
      opacity: 0;
    }

    &-icon {
      display: block;
      width: 30px;
      height: 30px;
    }
  }

  //&-faculty-link {
  //
  //}
}

.navigation-home {
  margin-top: rem(10);

  &-link {
    display: inline-block;
    padding: rem(2);
    line-height: 1;
    color: $uks-color-text;
  }

  &-link-current {
    cursor: default;
    opacity: .25;

    &:hover {
      text-decoration: none;
    }
  }

  &-link-icon {
    display: block;
    width: rem(16);
    height: rem(16);
  }
}
