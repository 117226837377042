////
/// @author cosmoblonde
/// @group Fonts
////

/// We only serve WOFF fonts. Older browsers that do not support WOFF do not get font-face fonts.
/// @param {String} $family Name of font-family
/// @param {String} $filename Name of the file, without file extension
/// @param {String} $woff2 [0] Include woff2 src? (bool)
/// @param {String} $fontweight [normal] Font-weight of this font
/// @param {String} $fontstyle [normal] Font-style of this font
/// @output Complete @font-face block for a single font
/// @example scss Where filename of font is avenirbook.woff
///   @include font-face('Avenir Book', avenirbook);
/// @example scss Where filename of font is avenirbookbold.woff
///   @include font-face('Avenir Book Funky', avenirbookbold, 1, bold, italic);
@mixin font-face($family, $filename, $woff2: 0, $fontweight: normal, $fontstyle: normal) {
  @font-face {
    font-family: "#{$family}";
    @if ($woff2 != 0) {
      src: url($path-fonts + "#{$filename}.woff2") format("woff2"), url($path-fonts + "#{$filename}.woff") format("woff");
    } @else {
      src: url($path-fonts + "#{$filename}.woff") format("woff");
    }
    font-style: #{$fontstyle};
    font-weight: #{$fontweight};
    font-display: swap;
  }
}
