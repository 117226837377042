.lightbox {
  @include opacity-transition;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height $uks-duration-default, width $uks-duration-default;

  &--active {
    left: 50%;
    z-index: 101;
    // Initial values just to stop it displaying collapsed while waiting for image to load
    width: 75vw;
    // Must match .lightbox__measurer-image max-width/max-height
    max-width: calc(100% - 20px);
    height: 75vh;
    // Must match .lightbox__measurer-image max-width/max-height
    max-height: calc(100vh - 90px);
    overflow: visible;
    opacity: 1;
    transform: translate(-50%, -50%);

    // Must match .lightbox__measurer width/height
    @include respond-up-from($uks-screen-small-2) {
      max-width: calc(100% - 130px);
      max-height: calc(100vh - 20px);
    }
  }

  // Off-screen container for measuring lightbox images as they load
  &__measurer {
    position: absolute;
    top: 0;
    right: 110%;
    // Same max-width/max-height as visible layer, but without transitions: used to instantly measure images before adding to main layer. Allows transitions of height/width on visible layer
    width: calc(100% - 20px);
    height: calc(100vh - 90px);

    @include respond-up-from($uks-screen-small-2) {
      // Same max-width/max-height as visible layer, but without transitions: used to instantly measure images before adding to main layer. Allows transitions of height/width on visible layer
      width: calc(100% - 130px);
      height: calc(100vh - 20px);
    }
  }

  &__measurer-image {
    @include responsive-img;
    width: auto;
    max-height: 100%;
  }

  &__overlay {
    @include opacity-transition;
    z-index: -1;
    opacity: 0;

    &--active {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
      background: $white;
      opacity: .8;
    }
  }

  &__figure {
    height: 100%;
    background: $white-transparent;
  }

  &__image {
    @include responsive-img;
    @include opacity-transition;
    width: auto;
    max-height: 100%;
    margin: 0 auto;
    opacity: 1;

    &--loading {
      opacity: .55;
    }
  }

  &__caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $uks-color-tones-14;

    &--empty {
      display: none;
    }
  }

  &__caption-text {
    flex: 1 1 auto;
    padding: rem(15) rem(15) rem(16);
    font-size: rem(15);
    line-height: (18 / 15);
    color: $white;

    &:empty {
      display: none;
    }
  }

  &__controls {
    flex: 0 0 auto;
    padding: rem(15) rem(15) rem(16) 0;
    text-align: right;

    &::after {
      display: none;
    }

    &[aria-hidden="true"] {
      display: none;
    }
  }

  &__control {
    &::after {
      position: relative;
      display: block;
      width: rem(30);
      height: rem(30);
      content: "";
      background: $uks-svg-arrow-medium-white no-repeat 50% 50%;
      background-size: 100% auto;
      transform: rotate(-90deg);
    }


    &.js-disabled {
      cursor: default;
      opacity: .4;
    }

    .lightbox[aria-hidden="true"] & {
      display: none;
    }

    &--next {
      margin-left: rem(10);

      &::after {
        transform: rotate(90deg);
      }
    }

    &.js-hidden {
      display: none;
    }

  }

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    width: rem(24);
    height: rem(24);
    padding: rem(7);
    background: $uks-svg-enlarge-close no-repeat 50% 50%;
    background-size: 100% auto;

    .lightbox[aria-hidden="true"] & {
      display: none;
    }

    &:focus {
      outline-offset: 0;
    }
  }

  // General override as Bootstrap leaves buttons untouched
  button {
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    /* stylelint-enable property-no-vendor-prefix */
  }
}

