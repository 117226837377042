.contentslider {
  #{--slider-color}: $uks-color-tones-18;

  // Lightgreen default
  background: var(--slider-color);

  &--lightblue {
    #{--slider-color}: $uks-color-tones-13;
  }

  &--yellowgreen {
    #{--slider-color}: $uks-color-tones-19-alt;
  }

  &--wide {
    display: none;

    @include respond-up-from($uks-screen-medium-2) {
      display: block;
    }
  }

  &--narrow {
    padding: rem(8) rem(15) rem(30) rem(12);
    border-color: $black;
    border-bottom: 0;

    @include respond-up-from($uks-screen-medium-2) {
      display: none;
    }

  }

  &__controls {
    position: relative;
    padding: 16px 60px 40px;
  }

  &__controls-liner {
    position: relative;
    width: 100%;
    overflow: hidden;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: rem(50);
      pointer-events: none;
      content: "";
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--slider-color) 100%);
    }
  }

  &__control-list {
    padding: 0;
    margin: 0;
    list-style: none;
    border: 0;
    transition: transform .4s;

    @include respond-up-from($uks-screen-medium-2) {
      display: flex;
      gap: 0 rem(42);
    }
  }

  &__control {
    position: relative;
    font-size: rem(18);
    line-height: (36 / 18);
    color: inherit;
    text-decoration: none;

    @include respond-up-from($uks-screen-medium-2) {
      display: block;
      flex: 0 0 auto;
      padding-bottom: rem(12);
      font-size: rem(20);
      line-height: (38 / 20);
      white-space: nowrap;

      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: rem(1);
        content: "";
        background: $black;
      }

      &[aria-selected="true"] {
        font-weight: 700;

        &::after {
          bottom: rem(-3);
          height: rem(7);
        }
      }
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }

  }

  &__paging-control {
    @include text-button;
    position: absolute;
    top: rem(22);
    // Leave as px
    right: 20px;
    width: rem(26);
    height: rem(26);
    background: $uks-svg-arrow-thick-black no-repeat 50% 50%;
    background-size: auto 100%;

    &--prev {
      right: auto;
      // Leave as px
      left: 20px;
      transform: rotate(180deg);
    }

    &[disabled] {
      display: none;
    }
  }

  &__content {
    @include respond-up-from($uks-screen-medium-2) {
      @include opacity-transition;
      padding: 0 60px 48px;

      &[aria-hidden="true"] {
        height: 0;
        padding: 0;
        overflow: hidden;
        opacity: 0;

        > * {
          display: none;
        }
      }
    }
  }

  &__title {
    margin-bottom: rem(9);
    font-family: $font-family;
    font-size: rem(30);
    font-weight: 700;
    line-height: (41 / 30);

    @include respond-up-from($uks-screen-medium-2) {
      margin-bottom: rem(13);
      font-size: rem(40);
      line-height: (52 / 40);
    }
  }

  &__text {
    width: 100%;
    max-width: rem(740);
    font-size: rem(18);
    line-height: (30 / 18);

    @include respond-up-from($uks-screen-medium-2) {
      font-size: rem(20);
      line-height: (32/ 20);
    }

    > *:last-child {
      margin-bottom: 0;
    }

    // RTE output, no classes
    a {
      color: inherit;

      &:not([class]) {
        text-decoration: underline;
      }
    }
  }

  &__button-link {
    margin-top: rem(7);
  }

  &__dotlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(10);
    padding-bottom: 26px;
    margin: 0;
    line-height: rem(12);
    list-style: none;
  }

  &__dotlist-item {
    flex: 0 0 auto;
    margin: 0;
  }

  &__dotlist-dot {
    @include text-button;
    width: rem(12);
    height: rem(12);
    cursor: pointer;
    background: $black;
    border: 1px solid $black;
    border-radius: 100%;

    &--current {
      cursor: default;
      background: transparent;
    }
  }

}
