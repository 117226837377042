.custom-control {
  //display: inline-flex;
  //margin-right: rem(30);
  font-size: 1rem;
  font-weight: 400;
}

.custom-control-label::before {
  border: 1px solid $uks-color-tones-14;
}

.custom-control-input {
  &:focus ~ .custom-control-label::before {
    outline: 1px dotted $black;
    box-shadow: none;
  }

  &:active ~ .custom-control-label::before {
    background: transparent;
  }
}
