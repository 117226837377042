.resume {
  padding-bottom: 45px;
  margin-bottom: $uks-component-margin;
  border-bottom: 1px solid $uks-color-border;

  &-position {
    margin: rem(-20) 0 rem(40);
  }

  &-image {
    text-align: center;

    &:not(:last-child) {
      margin-bottom: rem(30);
    }
  }

  &-split-section {
    @include clearfix;

    .resume-title {
      @include media-breakpoint-up(lg) {
        //@include hyphens;
        float: left;
        // proportional width calculated from layout measurements
        width: (250 / 720) * 100%;
        padding-right: 30px;
      }
    }

    .resume-list {
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        float: left;
        // proportional width calculated from layout measurements
        width: (470 / 720) * 100%;
      }
    }
  }

  &-detail {
    @include media-breakpoint-up(lg) {
      float: left;
      // proportional width calculated from layout measurements
      width: (345 / 720) * 100%;

      &-left {
        // proportional width calculated from layout measurements
        width: (375 / 720) * 100%;
        padding-right: 30px;
      }
    }
  }

  &-deflist {
    margin-bottom: 0;

    + .resume-deflist {
      margin-top: rem(22);
    }

    /* stylelint-disable selector-no-qualifying-type  */
    dt.standalone {
      margin-top: rem(10);
    }

    dd {
      margin-bottom: rem(20);

      &:last-child {
        margin-bottom: 0;
      }

      &.standalone {
        @include media-breakpoint-up(sm) {
          margin-top: rem(10);
        }
      }
    }
    /* stylelint-enable  */

    &-horizontal {
      width: 100%;

      @include media-breakpoint-up(sm) {
        dt {
          display: inline-block;
          width: 120px;
          max-width: 100%;
          vertical-align: top;
        }
        dd {
          display: inline-block;
          // 125px to allow for accidental whitespace between dt and dd. But should not be needed if template correct
          width: calc(100% - 125px);
          margin-bottom: 0;
        }
      }
    }
  }

  .component {
    margin-bottom: 0;
  }

  hr {
    margin: 45px 0;
  }

  .image-copyright {
    background: $white;
  }
}
