.section-header {
  position: relative;
  margin: 0 0 rem(24);
  font-size: rem(24);
  line-height: (28 / 24);
  text-align: center;
  text-transform: uppercase;

  &::before {
    position: absolute;
    right: 0;
    bottom: 50%;
    left: 0;
    height: 1px;
    content: "";
    background: $uks-color-tones-14;
  }

  &__text {
    position: relative;
    display: inline-block;
    padding: 0 rem(14);
    background: $white;

    .start-page & {
      background: $uks-color-tones-7;
    }
  }
}
