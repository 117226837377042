// UKS-specific page layout (header / footer)
.page-header {
  background: $white;

  &-liner {
    position: relative;
    padding: rem(5) 0 0;
    border-bottom: 1px solid $uks-color-primary;

    .page-header-faculty & {
      margin-bottom: $uks-component-margin;
    }

    .page-header-faculty-nospace & {
      margin-bottom: 0;
    }
  }

  &-container {
    position: static;
  }

  &-ci {
    position: relative;
    box-sizing: content-box;
    min-height: 69px;
    margin-top: rem(33);

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      min-height: 89px;
      margin-top: rem(4);
    }

    // Need Bootstrap default above this width, so us a breakpoint-down as an exception. Because of Bootstrap weirdness need to feed it the breakpoint *below* the one we want (uksfullnav). Is this a bug?
    @include media-breakpoint-down(ukssm, $uks-altgrid-breakpoints) {
      // Keep as px
      padding-right: 60px;

      // Specificity
      //.page-header-faculty-standard.page-header-faculty-standard & {
      //  height: auto;
      //  min-height: 0;
      //}

      // Specificity
      .page-header-faculty.page-header-faculty & {
        height: auto;
        padding-bottom: 0;
        margin-top: rem(4);
      }
    }

    .page-header-faculty-neutral & {
      padding-bottom: 40px;
    }

    .page-header-faculty-standard & {
      height: 101px;
      padding-bottom: 0;
      margin-top: 0;
    }
  }

  &-meta {
    position: static;
    text-align: right;

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      min-height: rem(40);
    }
  }
}

.page-logo {
  // Logo required to be 197px * 38px (email from Herr Rudewig, 30.01.2018), but supplied graphic (Logo_Universitaet-01.svg, 28(?).03.2018) does not have those dimensions. Assuming height is more important than width:
  //width: 197px;
  width: auto;
  max-height: 30px;

  @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
    width: 199px;
    height: 40px;
    max-height: 40px;
    margin-top: -2px;
  }

  &-default {
    .page-header-faculty & {
      display: inline;

      @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
        display: none;
      }
    }
  }

  &-faculty {
    display: none;

    @include media-breakpoint-up(uksfullnav, $uks-altgrid-breakpoints) {
      display: inline;
    }

    .page-header-faculty-standard & {
      width: auto;
      height: 100px;
      max-height: 100px;
      margin-top: -1px;
    }
  }

}

// Sometimes issues with focus ring on the link as not display: block/inline-block - move to image
.page-logo-link {
  &:focus {
    outline: none;

    .page-logo {
      outline: 1px dotted $black;
    }
  }
}

.page-content {
  position: relative;
  margin-top: rem(40);

  &-with-carousel {
    margin-top: 0;
  }

  // Breadcrumb
  .container--breadcrumb + & {
    margin-top: 0;
  }
}

.page-footer {
  margin-top: $uks-component-margin;
  font-size: rem(13);
  line-height: (18 / 13);
  border-top: 1px solid $uks-color-primary;

  &-liner {
    padding: rem(25) 0 rem(20);
  }

  &-col {
    position: relative;
    margin: 0 0 rem(12);
  }

  &-col-plain {
    // Only really relevant for the col containing the to-top link. May need to be more specific if class re-used
    min-height: rem(35);
  }

  &-contact {
    @include clearfix;
    margin: 0 0 $uks-component-margin;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: flex-start;
      margin-top: $uks-component-margin;
    }

    &-addresses {
      @include media-breakpoint-up(md) {
        flex: 1 1 auto;
        float: left;
      }
    }

    // Default rendering so cannot use additional class
    h3 {
      margin-bottom: rem(8);
      font-size: rem(16);
      line-height: (24 / 16);
    }
  }
}

.page-blog {

  .linklist-jumpblock .linklist [class^="link-"].link-local::before {
    filter: none;
  }

  .blog-main-image {
    margin: 25px 0 50px 0;
    text-align: center;
    background: #b9ded5;

    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 630px;
    }
  }

  //.post__title,
  .content-text,
  h2,
  .jumpblock-wrapper,
  .news,
  .graph,
  > main > .row {
    //margin: 0;

    @include media-breakpoint-up(md) {
      max-width: 755px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .blockquote {
    display: block;
    margin: 0;

    @include media-breakpoint-up(md) {
      max-width: 755px;
      margin: 25px auto 0 auto;
    }
  }

  .linklist-wrapper {
    & > .h2:not(.sr-only) {
      padding-left: 0;
    }

    .linklist {
      padding-left: 0;
    }
  }

  .carousel {
    img {
      width: 100%;
      height: auto;
    }
  }

  .blog-more {
    display: inline-block;
    width: 100%;
  }

  .blog-more-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }

  .blog-more-title::before,
  .blog-more-title::after {
    flex: 1 0 20px;
    margin: 0 20px 0 0;
    color: #9b1047;
    content: "";
    border-top: 2px solid;
  }

  .blog-more-title::after {
    margin: 0 0 0 20px;
  }

}
