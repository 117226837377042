.event {
  &-list-item {
    .card-date {
      width: 180px;
      padding-right: 30px;
    }

    .card-date-month {
      display: block;
    }
  }
}
