////
/// @author CB
/// @group Media Queries
////
/* stylelint-disable media-feature-name-disallowed-list */

/// Media query to apply styles up to a max-width.
///
/// **Always** use variables ($screen-[xxx]) for media query params. If a suitable variable is not available, create one.
///
/// For accessibility reasons, media queries should always use *em* units unless there is a specific reason to do otherwise.
/// @param {Size + unit} $media Must **always** be a variable from _settings.scss.
/// @param {Size + unit} $pixel [$one-pixel] Offset to ensure media-queries do not overlap (<= 1px). Unit must match the unit in $media.
/// @require $one-pixel
/// @content
/// @output Media-query wrapping $content
/// @example scss Basic
///   .foo {
///       @include respond-up-to($screen-medium-1) {
///           margin-left: 30px;
///       }
///   }
/// @example scss Nested
///   .foo {
///       @include respond-up-to($screen-medium-1) {
///           margin-left: 30px;
///
///           .bar {
///               width: auto;
///           }
///       }
///   }
/// @example scss Special case using px - avoid unless absolutely necessary
///   .foo {
///       @include respond-up-to($screen-wide-px, 1px) {
///           overflow: hidden;
///       }
///   }
@mixin respond-up-to($media, $pixel: $one-pixel) {
  @media only screen and (max-width: $media - $pixel) {
    @content;
  }
}

/// Media query to apply styles up from a min-width.
///
/// **Always** use variables ($screen-[xxx]) for media query params. If a suitable variable is not available, create one.
///
/// For accessibility reasons, media queries should always use *em* units unless there is a specific reason to do otherwise.
/// @param {Size + unit} $media Must **always** be a variable from _settings.scss.
/// @content
/// @output Media-query wrapping $content
/// @example scss Basic
///   .foo {
///       @include respond-up-from($screen-large-1) {
///           margin-left: 30px;
///       }
///   }
/// @example scss Nested
///   .foo {
///       @include respond-up-from($screen-large-1) {
///           margin-left: 30px;
///
///           .bar {
///               width: auto;
///           }
///       }
///   }
@mixin respond-up-from($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

/// Media query to apply styles between a min-width and a max-width.
///
/// **Always** use variables ($screen-[xxx]) for media query params. If a suitable variable is not available, create one.
///
/// For accessibility reasons, media queries should always use *em* units unless there is a specific reason to do otherwise.
/// @param {Size + unit} $media-low Min-width. Must **always** be a variable from _settings.scss.
/// @param {Size + unit} $media-high Max-width. Must **always** be a variable from _settings.scss.
/// @param {Size + unit} $pixel [$one-pixel] Offset to ensure media-queries do not overlap (<= 1px). Unit must match the unit in $media-high.
/// @require $one-pixel
/// @content
/// @output Media-query wrapping $content
/// @example scss Basic
///   .foo {
///       @include respond-between($screen-medium-1, $screen-large-1) {
///           margin-left: 30px;
///       }
///   }
/// @example scss Nested
///   .foo {
///       @include respond-between($screen-medium-1, $screen-large-1) {
///           margin-left: 30px;
///
///           .bar {
///               width: auto;
///           }
///       }
///   }
/// @example scss Special case using px - avoid unless absolutely necessary
///   .foo {
///       @include respond-between($screen-small-px, $screen-wide-px, 1px) {
///           overflow: hidden;
///       }
///   }
@mixin respond-between($media-low, $media-high, $pixel: $one-pixel) {
  @media only screen and (min-width: $media-low) and (max-width: $media-high - $pixel) {
    @content;
  }
}
/* stylelint-enable media-feature-name-disallowed-list */
