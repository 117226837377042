.steps {
  @include clearfix;

  &-title {
    //@include hyphens;

    @include media-breakpoint-up(lg) {
      float: left;
      // proportional width calculated from layout measurements
      width: (250 / 720) * 100%;
      padding-right: 30px;
    }
  }

  &-list {
    padding: 0;
    margin: 0;
    list-style: none;

    @include media-breakpoint-up(lg) {
      float: left;
      // proportional width calculated from layout measurements
      width: (470 / 720) * 100%;
    }
  }

  &-item {
    @include clearfix;
    margin: 0;
  }

  &-date,
  &-location {
    position: relative;
    display: block;

    @include media-breakpoint-up(sm) {
      float: left;

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        content: "";
        background: $uks-color-secondary;
      }
    }

    .steps-item:last-child & {
      padding-bottom: 0;
    }
  }

  &-date {
    font-family: $font-family-alt;
    font-weight: 700;

    @include media-breakpoint-up(sm) {
      // proportional width calculated from layout measurements
      width: (125 / 470) * 100%;
      padding-right: 30px;
      padding-bottom: rem(10);
      text-align: right;

      &::after {
        right: 0;
      }
    }
  }

  &-location {
    padding-bottom: rem(10);
    color: $uks-color-text-light;

    @include media-breakpoint-up(sm) {
      // proportional width calculated from layout measurements
      width: (345 / 470) * 100%;
      padding-left: 34px;

      &::after {
        left: -1px;
      }
    }

    strong {
      font-weight: 400;
      color: $black;
    }
  }
}
