.pagination {
  &-wrapper {
    margin: rem(40) 0 $uks-component-margin;
  }
}

.page-item {
  margin-bottom: 0;
  font-family: $font-family-alt;

  &.active {
    font-weight: 700;
  }

  &.disabled {
    opacity: .25;
  }
}

.page-link {
  &-prev,
  &-next {
    width: 13px;
    height: 24px;
    padding: 0;
    margin: 0 0 0 10px;
    background: $uks-svg-arrow-thick no-repeat 50% 50%;
  }

  &-prev {
    margin: 0 10px 0 0;
    transform: rotate(180deg);
  }
}
/* stylelint-disable selector-no-qualifying-type  */
// Must only apply to a-tags
a.page-link:hover {
  text-decoration: underline;
}

a.page-link--current:hover {
  text-decoration: none;
}
/* stylelint-enable */

.page-item.active .page-link--current {
  cursor: text;
}
