.component {
  margin-bottom: $uks-component-margin;

  h2 {
    color: $black;
  }

  &__subheader {
    margin-bottom: rem(20);
  }

}
