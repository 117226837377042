.blockquote {
  position: relative;
  padding: 40px 25px 0 21px; // Overwrite
  margin-top: 40px;
  margin-bottom: 0;
  font-family: $font-family;
  //font-style: italic;
  font-weight: 700;
  line-height: rem(28);

  &--content {
    display: inline-block;
  }

  &::before {
    position: absolute;
    top: rem(-20);
    left: 23px;
    width: 53px;
    height: 46px;
    content: "";
    background: $uks-svg-quote-black no-repeat;
    background-size: contain;
  }

  p {
    &::before {
      content: "\201E";
    }

    &::after {
      content: "\201C";
    }
  }

  &-footer {
    position: relative;
    margin-top: rem(-2);
    font-size: rem(14);
    font-style: normal;
    font-weight: 400;

    &::before {
      display: none; // Overwrite
    }
  }

  &-lightgreen {
    background: $uks-color-tones-18;
  }

  &-lightblue {
    background: $uks-color-tones-17;
  }

  &-blue {
    background: $uks-color-tones-13-dark;
  }

  .column-1 {
    float: left;
    width: 55%;
  }

  .column-2 {
    float: left;
    width: 45%;
  }

  .content-image {
    img {
      width: 100%;
      height: auto;
      margin-top: -60px;
    }
  }

  @include media-breakpoint-down(md) {
    .column-1,
    .column-2 {
      float: none;
      width: 100%;
    }

    .content-image {
      img {
        margin-top: 20px;
      }
    }
  }
}
