// Still trying not to inject non-BS classes, but this is *really* fragile and may interfere with other elements using tabpanel approach:

*[data-toggle="collapse"] {
  position: relative;
  display: block;
  padding-right: 60px;
  font-size: rem(17);
  line-height: (20 / 17);
  color: $black;

  &::after {
    position: absolute;
    top: rem(4);
    right: rem(30);
    width: rem(13);
    height: rem(13);
    content: "";
    background: $uks-svg-plus no-repeat 50% 50%;
    background-size: 100% auto;
  }

  &[aria-expanded="true"] {
    &::after {
      background-image: $uks-svg-minus;
    }
  }
}

*[role="tablist"],
.tablist {
  border-bottom: 1px solid $card-border-color;

  > .card {
    border-width: 1px 0 0;

    &:not(:first-child) {
      margin-top: -1px;
    }

    > .card-header {
      padding: rem(12) 0 rem(14);
      border-bottom: 0;
    }
  }

  &.component-accordion--colored {
    border-bottom: 0;

    > .card {
      border-color: transparent;
      border-width: 3px 0 0;

      &:first-child {
        border-width: 0;
      }

      &:not(:first-child) {
        margin-top: -3px;
      }

      > .card-header {
        padding-left: rem(20);
        background-color: $uks-color-tones-7-alt;
      }
    }

    .collapsing,
    .show {
      padding-top: rem(12);
    }
  }

  &.component-accordion--lightgreen {
    > .card {
      > .card-header {
        background-color: $uks-color-tones-18;
      }
    }
  }

  &.component-accordion--lightblue {
    > .card {
      > .card-header {
        background-color: $uks-color-tones-17;
      }
    }
  }

  &.component-accordion--colored [data-toggle="collapse"] {
    &:hover {
      color: $uks-color-text;
    }
  }

  &.component-accordion--colored:not(.component-accordion--grey) [data-toggle="collapse"] {
    &::after {
      filter: grayscale(1);
    }
  }
}

.collapsing,
.collapse {
  > .card-block {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

.collapse-teaser {
  display: block;
  margin-top: rem(8);
  font-family: $font-family;
  font-size: 1rem;
  font-weight: 400;
  line-height: $line-height-base;
}
