.postlist {
  padding-top: rem(24);

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;

    &::after {
      display: block;
      height: 0;
      clear: both;
      content: "";
    }
  }

  &__post {
    margin-bottom: rem(56);
  }

  &__header {
    position: relative;
    display: flex;
    flex-wrap: wrap-reverse;
    padding: rem(25) rem(16) rem(16) rem(26);
    background: $uks-color-tones-19;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }

    &--1 {
      background: $uks-color-tones-18;
    }

    &--2 {
      background: $uks-color-tones-13;
    }
  }

  &__title {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin-bottom: rem(16);
    font-size: rem(28);
    font-weight: 700;
    line-height: (32 / 28);

    @include media-breakpoint-up(md) {
      font-size: rem(35);
      line-height: (40 / 35);
    }
  }

  &__meta {
    display: block;
    order: 1;
    margin-bottom: rem(6);
    font-size: rem(16);
    font-weight: 400;
    line-height: (19 / 16);
    text-transform: uppercase;

    @include media-breakpoint-down(md) {
      @include hyphens;
    }
  }

  &__header-text {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 50%;
      padding-right: rem(24);
    }
  }

  &__image-wrapper {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: rem(18);

    @include media-breakpoint-up(md) {
      width: 50%;
      margin: 0;
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    margin-top: rem(-42);
  }

  &__link {
    order: 2;
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      content: "";
    }
  }

  &__tags {
    position: relative;
    min-height: rem(30);
    padding: rem(11) rem(30) 0 0;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      width: rem(20);
      height: rem(30);
      content: "";
      background: $uks-svg-arrow-thick no-repeat 100% 0;
      filter: grayscale(1);
      background-size: auto 100%;
    }
  }

  &__tag-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__tag-list-item {
    display: inline-block;
    padding: 0;
    margin: 0 rem(12) 0 0;
    font-size: rem(16);
    line-height: (19 / 16);
  }

  &__description {
    padding: rem(12) rem(16) 0 rem(26);
    margin: 0;
  }

  .postlist__list__layout_1 {

    .postlist__post {
      margin-bottom: 1.5rem;
    }

    .postlist__item {
      float: none;
      width: 100%;
      margin-bottom: 0;

      @include media-breakpoint-up(md) {
        float: left;
        width: 50%;
      }
    }

    .postlist__header {
      display: inline-block;
      width: 100%;
      padding: 0;
      background: none;
    }

    .postlist__header-text {
      width: 100%;
    }

    .postlist__title {
      padding: 15px 15px 0 15px;
      font-size: 1rem;
    }

    .postlist__link {

      &::after {
        display: inline-block;
        width: rem(20);
        height: rem(14);
        vertical-align: middle;
        content: "";
        background: $uks-svg-arrow-thick no-repeat 100% 0;
        background-size: auto 100%;
        transform: translateY(rem(-1));
      }

    }

    .postlist__image {
      width: 100%;
      margin-top: 0;
    }

    .item_0 {
      float: none;
      width: 100%;

      .postlist__header {
        background: $uks-color-tones-18;
      }

      .postlist__title {
        padding: 20px 20px 5px 20px;
        font-size: 1.6rem;
      }

      .postlist__link {
        &::after {
          width: rem(20);
          height: rem(30);
          filter: grayscale(1);
          background-position: 100% 50%;
          background-size: auto 60%;
        }
      }
    }
  }
}

.postteaser__list_layout_1 {
  display: inline-block;

  .postteaser__post {
    display: inline-block;
    float: none;
    width: 100%;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      float: left;
      width: 50%;
    }

    .postteaser__posttitle {
      padding: 15px 15px 0 15px;
      font-size: 1rem;
    }

    &.item_0 {
      float: none;
      width: 100%;

      background: $uks-color-tones-18;

      .postteaser__posttitle {
        padding: 20px 20px 5px 20px;
        font-size: 1.6rem;
      }
    }

    .postlist__image {
      width: 100%;
      margin-top: 0;
    }

    a {
      color: $uks-color-text;
    }
  }
}

// Specificity
.page-blog.page-blog {

  .postlist__item {
    float: none;
    width: 100%;

    @include media-breakpoint-up(md) {
      float: left;
      width: 33%;
    }
  }

  .postlist__header {
    display: inline-block;
    width: 100%;
    padding: 0;
    background: none;
  }

  .postlist__header-text {
    width: 100%;
  }

  .postlist__title {
    padding: 10px;
    margin: 0;
    font-size: 1rem;

    a {
      color: $uks-color-text;
    }
  }

  .postlist__image {
    width: 100%;
    margin: 0;
  }
}
