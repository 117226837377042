.card {
  &--transparent {
    background-color: transparent;
  }

  &--contentslider {
    border-color: $black;

    &:first-child {
      border-top: 0;
    }

    // Specificity
    &.card--contentslider:last-child {
      border-bottom: 1px solid $black;
    }
  }

  &-header {
    &--transparent {
      background-color: transparent;

      [data-toggle="collapse"] {
        &::after {
          filter: grayscale(100%);
        }

        &:hover {
          color: inherit;
        }
      }
    }

    &--large {
      // Fighting Bootstrap
      [role="tablist"] > .card.card > &,
      .tablist > .card.card > & {
        padding-top: rem(21);
        padding-bottom: rem(23);
      }

      [data-toggle="collapse"]::after {
        right: 0;
        width: rem(16);
        height: rem(16);
      }
    }
  }

  &-teaser {
    color: $black;

    strong {
      //color: $uks-color-text;
      color: $black;
    }

    // Should never be needed, but ensure spacing OK if multiple teasers in a single component
    + .card-teaser {
      margin-top: $uks-component-margin;
    }

    // Special case - news teaser as plugin in Newswall. Need to make it full height. Messy workaround (only when no element headline)
    .wall__item .news:first-child > & {
      height: 100%;
    }

    .btn-primary {
      color: $black;
      background-color: transparent;
      border: 1px solid $black;

      &:hover {
        color: $white;
        background-color: $uks-color-tones-14;
      }
    }

    .linklist {
      margin-top: 10px;
    }

    .counterlist {
      padding-left: 10px;
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      counter-reset: item;

      &__last-item-noline {
        .counterlist__item:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: none;
        }
      }

      .counterlist__item {
        position: relative;
        min-height: 50px;
        padding-top: 0;
        padding-bottom: 20px;
        padding-left: 60px;
        margin-top: 0;
        margin-bottom: 20px;
        //text-indent: -3px;
        counter-increment: item;
        border-bottom: 1px solid $white;

        &::before {
          position: absolute;
          display: inline-block;
          width: 45px;
          height: 40px;
          //margin-right: 10px;
          margin-left: -67px;
          font-size: 30px;
          line-height: 120%;
          text-align: right;
          content: counter(item);
        }
      }
    }
  }

  &-teaser-inverse {
    background: $uks-color-border;
  }

  &-teaser-sand {
    background: $uks-color-tones-12;
    border-color: $uks-color-tones-12;
  }

  &-teaser-teal {
    background: $uks-color-tones-11;
    border-color: $uks-color-tones-11;
  }

  &-teaser-blue {
    background: $uks-color-tones-13;
    border-color: $uks-color-tones-13;
  }

  /*
        unired
        lightgrey
        goldyellow
        lightblue
        lightgreen
        yellowgreen

   */
  &-teaser-unired {
    background: $white;
    border-color: $uks-color-tones-14;
    .counterlist .counterlist__item {
      border-bottom-color: $uks-color-tones-14;
    }

    &--filled {
      color: $white;
      background: $uks-color-tones-14;

      strong,
      .linklist [class^="link-"],
      .card-text,
      .card-title {
        color: $white;
      }

      .counterlist .counterlist__item {
        border-bottom-color: $white;
      }

      .linklist [class^="link-"] {

        &.link-local::before,
        &.link-internal::before {
          background-image: $uks-svg-link-internal-white;
        }

        &.link-external::before {
          background-image: $uks-svg-link-external-white;
        }

        &.link-download::before {
          background-image: $uks-svg-link-download-white;
        }
      }

      .card-divider {
        border-top-color: $white;
      }

      .btn-primary {
        color: $white;
        border: 1px solid $white;

        &:hover {
          color: $uks-color-tones-14;
          background-color: $white;
        }
      }
    }
  }
  &-teaser-lightgrey {
    background: $white;
    border-color: $uks-color-tones-15;
    .counterlist .counterlist {
      &__item {
        border-bottom-color: $uks-color-tones-15;
      }
    }
    &--filled {
      background: $uks-color-tones-15;

      strong {
        color: $black;
      }
      .counterlist .counterlist__item {
        border-bottom-color: $uks-color-text;
      }
      .card-divider {
        border-top-color: $uks-color-text;
      }
    }
  }
  &-teaser-goldyellow {
    background: $white;
    border-color: $uks-color-tones-16;
    .counterlist .counterlist {
      &__item {
        border-bottom-color: $uks-color-tones-16;
      }
    }
    &--filled {
      background: $uks-color-tones-16;
      strong {
        color: $black;
      }
      .counterlist .counterlist__item {
        border-bottom-color: $black;
      }
      .card-divider {
        border-top-color: $uks-color-text;
      }
    }
  }
  &-teaser-lightblue {
    background: $white;
    border-color: $uks-color-tones-17;
    .counterlist .counterlist {
      &__item {
        border-bottom-color: $uks-color-tones-17;
      }
    }
    &--filled {
      background: $uks-color-tones-17;
      strong {
        color: $black;
      }
      .counterlist .counterlist__item {
        border-bottom-color: $black;
      }
      .card-divider {
        border-top-color: $uks-color-text;
      }
    }
  }
  &-teaser-lightgreen {
    background: $white;
    border-color: $uks-color-tones-18;
    .counterlist .counterlist {
      &__item {
        border-bottom-color: $uks-color-tones-18;
      }
    }
    &--filled {
      background: $uks-color-tones-18;
      strong {
        color: $black;
      }
      .counterlist .counterlist__item {
        border-bottom-color: $black;
      }
      .card-divider {
        border-top-color: $uks-color-text;
      }
    }
  }
  &-teaser-yellowgreen {
    background: $white;
    border-color: $uks-color-tones-19;
    .counterlist .counterlist {
      &__item {
        border-bottom-color: $uks-color-tones-19;
      }
    }
    &--filled {
      background: $uks-color-tones-19;
      strong {
        color: $black;
      }
      .counterlist .counterlist__item {
        border-bottom-color: $black;
      }
      .card-divider {
        border-top-color: $uks-color-text;
      }
    }
  }

  &-block {
    //Bootstrap defines padding as just padding: $card-spacer-x; Have to re-define. Don't need rem here
    padding: $uks-card-padding;

    &--spaced {
      padding-bottom: rem(60);
    }

    .card-img-top + & {
      padding-top: $card-spacer-y;
    }

    // Non-Bootstrap class
    .card-teaser & {
      padding: $card-spacer-y $uks-teaser-spacer-right $uks-teaser-spacer-bottom $uks-teaser-spacer-left;
    }

    .card-teaser.card-teaser-inverse .card-img-top + &,
    .card-teaser .card-img-top + & {
      padding-top: 20px;
    }

  }

  &-title {
    // Bootstrap examples use H4, but for a11y these headlines probably have to be H2. Reset:
    margin-bottom: 0;
    font-size: rem(18);
    font-weight: 700;
    line-height: (24 / 20);

    &:not(:last-child) {
      margin-bottom: rem(12);
    }

    &::after {
      display: none;
    }

    // Non-Bootstrap class
    .card-teaser &,
    .card-teaser-event & {
      //line-height: (30 / 24);
      //font-size: rem(24);
      font-weight: 400;
    }

    .card-teaser & {
      /* font-weight like old emphasis-card-title */
      font-weight: 700;
    }

    //.card-topnews & {
    //    font-weight: 700;
    //}

    + .card-content .counterlist {
      margin-top: 25px;
    }
  }

  &-content {
    + .card-tags {
      margin-top: .75rem;
    }
  }

  &-tags {
    font-size: 15px;
    color: $uks-color-tones-5;
  }

  &.card-teaser-colored {
    .card-tags {
      color: $white;
    }
  }

  // Old Bootstrap classes, no longer supported so have to define
  &-primary {
    color: $white;
    background: $uks-color-primary-dark;
    border-color: $uks-color-primary-dark;

    .btn-info {
      color: $white;
      background: transparent;
      border-color: $white;
    }

    .card-title {
      color: $white;
    }
  }

  &-topnews.card-goldyellow {
    background-color: $uks-color-tones-16;
  }

  &-topnews.card-yellowgreen {
    background-color: $uks-color-tones-19;
  }

  // Borrow a standard class - by default this would have no bg color and would expect it to be defined inline. We give it a default
  &-inverse {
    color: $white;
    background: $uks-color-tones-4;
    border-color: $uks-color-tones-4;

    .card-title,
    .card-text {
      color: $white;
    }

    .btn-primary {
      color: $white;
      border-color: $white;
    }
  }

  // Non-Bootstrap but using same approach
  &-secondary {
    color: $black;
    background: $uks-color-secondary;
    border-color: $uks-color-secondary;

    strong {
      color: $black;
    }
  }

  // Editor-generated (RTE), so missing .card-block liner. Apply padding to card. Remove display:flex as not desired behaviour here - can only contain <strong> nodes etc.
  // Only card-outline-x classes available
  &-rte {
    display: block;
    padding: $uks-card-padding;
  }

  // Top news card
  &-topnews {
    &.card-primary {
      //@include card-variant($uks-color-primary-dark, $uks-color-primary-dark);
      color: $white;

      .card-title,
      strong {
        color: $white;
      }

    }
  }

  // Re-apply Bootstrap default border (as we have to set the var to 0 for other cards), but only when using a card-outline-* class
  &[class*="card-outline-"] {
    background-color: $white;
    //border-width: $uks-border-width;
    border-style: solid;
  }

  // Re-define a rather strange def in Bootstrap _card.scss
  &-outline-secondary {
    //@include card-outline-variant($uks-color-secondary);
  }

  // Some BS legacy classes included
  &-outline {
    &-info {
      border-color: $uks-color-secondary;
    }

    &-warning,
    &-danger,
    &-success {
      // Make sure complete icon is visible:
      min-height: 100px;
      padding-left: 50px;
      background: $uks-svg-success no-repeat 16px 27px;

      &.card-rte {
        padding-left: ($uks-card-padding-side + 50px);
      }
    }

    &-warning {
      background-image: $uks-svg-warning;
      background-position: 30px 27px;
      border-color: $uks-color-primary-dark;
    }

    &-danger {
      background-image: $uks-svg-danger;
      background-position: 30px 27px;
      border-color: $uks-color-primary-dark;
    }

  }

  // No show-more button - entire card is linked:
  &-link-all {
    // May be in Newswall, so:
    height: 100%;
    color: $uks-color-text;
    background-color: transparent;
    transition: background-color .1s ease-in;

    &:focus,
    &:hover {
      color: $uks-color-text;
      text-decoration: none;
      background-color: $uks-color-tones-1;
    }

    .card-topnews & {
      color: $black;
    }


    .card-topnews.card-goldyellow & {
      &:focus,
      &:hover {
        background-color: lighten($uks-color-tones-16, 2.5%);
      }
    }

    .card-topnews.card-yellowgreen & {
      &:focus,
      &:hover {
        background-color: lighten($uks-color-tones-19, 2.5%);
      }
    }

    .card-topnews.card-primary & {
      color: $white;

      &:focus,
      &:hover {
        background-color: darken($uks-color-primary-dark, 2.5%);
      }
    }

    .card-secondary & {
      &:focus,
      &:hover {
        background-color: $uks-color-secondary-light;
      }
    }

    .card-teaser-teal & {
      &:focus,
      &:hover {
        background-color: $uks-color-tones-11-dark;
      }
    }

    .card-teaser-blue & {
      &:focus,
      &:hover {
        background-color: $uks-color-tones-13-dark;
      }
    }
  }
  // CB invention, not part of Bootstrap
  // Additional styles in uks/modules/_linklist.scss
  &-list {
    padding: 0;
    margin: 0;
    list-style: none;
    counter-reset: cardlist;

    // Not clear how this element will be generated, so assume not practical to add classes to sub-elements
    li {
      position: relative;
      padding-left: rem(38);

      &:not(:last-child) {
        padding-bottom: rem(8);
        margin-bottom: rem(8);
        border-bottom: $uks-border-width solid rgba($white, .5);
      }

      &::before {
        position: absolute;
        top: rem(-1);
        left: 0;
        font-family: $font-family-alt;
        font-size: rem(32);
        font-weight: 700;
        line-height: (34 / 32);
        content: counter(cardlist);
        counter-increment: cardlist;
      }
    }
  }

  &-list-plain {
    li {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }

  // Bigger space for buttons following text
  &-content .linklist,
  &-text {
    + .btn {
      margin-top: rem(10);
    }
  }

  // Event teasers - CB
  &-date-small {
    display: block;
    margin-bottom: rem(13);
    font-family: $font-family-alt-medium;
    font-size: rem(20);
    line-height: (24 / 20);
    text-transform: uppercase;
  }

  &-date-group {
    display: inline-block;
    max-width: 100%;
    vertical-align: top;
  }

  &-date-day,
  &-date-separator {
    display: inline-block;
    max-width: 100%;
    font-family: $font-family-alt;
    font-size: rem(40);
    font-weight: 700;
    line-height: 1;
    color: $black;
    vertical-align: top;

    .card-teaser & {
      padding-right: rem(10);
    }

    .card-topnews.card-primary & {
      color: $white;
    }
  }

  &-date-separator {
    vertical-align: top;

    .card-teaser & {
      padding: 0 0 0 rem(8);
    }
  }

  &-date-month,
  &-meta {
    display: block;
    margin-bottom: rem(4);
    font-family: $font-family;
    font-size: rem(13);
    font-weight: 700;
    line-height: (20 / 13);
    text-transform: uppercase;
  }

  &-date-month {
    display: inline-block;
    max-width: 100%;
    vertical-align: top;
    transform: translateY(2px);
  }

  &-time {
    padding-top: rem(8);
    margin-bottom: rem(3);
    font-weight: 700;
  }

  &-meta {
    color: $uks-color-text-light;

    &--light {
      font-weight: 400;
      color: inherit;
      text-transform: none;
    }

    .card-teaser-colored & {
      color: inherit;
    }
  }

  &-cat {
    color: $uks-color-tones-9;

    .card-teaser-colored & {
      color: inherit;
    }
  }

  // Using standard classes on certain content, so some adjustments:
  .linklist {
    margin-bottom: 0;

    + p {
      margin-top: rem(20);
    }

    // Add specificity
    &.linklist + a {
      margin-top: rem(20);
    }
  }

  &-link-intro {
    margin-right: rem(6);

    + .card-list {
      display: inline-block;
    }
  }
  //p + .linklist {
  //    margin-top: rem(24);
  //}

  &-divider {
    margin: rem(8) 0;

    .card-secondary & {
      border-color: rgba($white, .5);
    }
  }
}
