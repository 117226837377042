.btn {
  // Not possible to use existing vars, forced to overwrite:
  font-size: rem(14);
  //text-transform: uppercase;
  cursor: pointer;
  border-width: $uks-border-width;

  &-sm {
    // Not possible to use existing vars, forced to overwrite:
    font-size: rem(10);
    font-weight: 400;
    //font-style: italic;
    text-transform: none;
  }

  &-primary {
    color: $black;
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;

  }
  &-secondary {
    background: $uks-color-tones-4;
    border-color: $uks-color-tones-4;
  }
}

.btn-danger:focus,
.btn-danger.focus,
.btn-primary:focus,
.btn-primary.focus {
  // Otherwise if an a-tag inherits global focus color
  color: $white;
}

form {
  .btn-primary:focus,
  .btn-primary.focus {
    background-color: $uks-color-primary;
    border-color: $uks-color-primary;
  }
}
