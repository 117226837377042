// Generic wrapper for RTE output
.content-text {
  color: $black;

  &:not(:last-child) {
    margin-bottom: $uks-component-margin-reduced;
  }

  > :last-child {
    margin-bottom: 0;
  }

  * + h3 {
    margin-top: rem(24);
  }

  strong {
    color: $uks-color-text;
  }

  ul:not(.linklist) {
    list-style-type: square;
  }
}
