.print {
  padding-left: rem(25);
  font-size: rem(13);
  line-height: (16 / 13);
  cursor: pointer;
  background: $uks-svg-print no-repeat 0 0;
  background-size: rem(16) auto;
  border: 0;

  &[disabled] {
    cursor: default;
  }
}

/* stylelint-disable declaration-no-important  */
@media print {
  * {
    float: none !important;
    overflow: visible;
    background: none !important;
  }

  .page-header-meta,
  .navigation-mobile-control,
  .navigation-mobile,
  .navigation,
  .page-header .back-link-wrapper,
  .page-content > .row > .col-lg-5,
  .page-footer-liner,
  .subnavigation,
  .carousel,
  .btn,
  .back-link,
  .to-top,
  .video,
  .cookie-notice,
  .print,
  .headline-link::after {
    display: none !important;
  }

  body {
    min-width: 0 !important;
    max-width: 100% !important;
  }

  .container {
    width: 100% !important;
    min-width: 0 !important;
    max-width: 100% !important;
  }

  .page-content > .row {
    display: block;

    .col-lg-11 {
      max-width: 100% !important;
    }
  }

  .icon-links [class^="link-"],
  .linklist [class^="link-"] {
    padding-left: 0 !important;

    &::before {
      display: none !important;
    }
  }

}

/* stylelint-enable */
