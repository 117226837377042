.person {
  &-table {
    td {
      // When there are exactly 2 TDs per row:

      @include media-breakpoint-up(sm) {
        &:nth-last-child(2):first-child {
          width: 40%;

          ~ td {
            width: 60%;
          }
        }
      }

    }
  }

  &-list {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    &-item {
      @include clearfix;
      padding-bottom: rem(10);
      margin-bottom: rem(20);
      border-bottom: 1px solid $table-border-color;

      h3 {
        margin-bottom: rem(16);
      }

      .media {
        // Overriding Bootstrap defaults, so use a breakpoint-down
        @include media-breakpoint-down(xs) {
          display: block;
        }
      }
    }

    &-info {
      margin-bottom: rem(10);
    }

    .image-wrapper {
      margin: rem(10) 0;

      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }
    }

    .image-copyright {
      background: none;
    }
  }

  &-contact {
    &-item {
      margin-bottom: 0;

      + .person-contact-item {
        margin-top: rem(-4);
      }

      .linklist {
        margin-top: rem(18);
      }
    }

    &-type {
      display: inline-block;
      min-width: rem(80);
      vertical-align: top;
    }

    strong {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      font-weight: 400;
    }
  }
}
