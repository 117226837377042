.list-linklist {
  display: inline-block;
  width: 100%;
  padding: 20px;
  background: #c0ddd5;

  &--select-title {
    width: 100%;
    margin: 5px 0;
    font-weight: 700;
  }

  select {
    width: 100%;
    padding: 5px;
    margin-bottom: 20px;
    border: 1px solid $uks-color-tones-14;
  }

  &--select-second {
    display: none;

    &.active {
      display: block;
    }
  }

  &--link-submit {
    color: $white;
    background: $uks-color-tones-14;
    border: none;

    &.disabled {
      color: $black;
      text-decoration: none;
      cursor: default;
      background: transparent;
      border: 1px solid $black;
    }

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
}
