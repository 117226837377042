.to-top {
  margin-bottom: $uks-component-margin;
  text-align: right;

  .component & {
    margin-top: rem(8);
    margin-bottom: rem(-18);
  }

  &-link {
    display: inline-block;
    width: 40px;
    height: 22px;
    background: $uks-svg-link-internal no-repeat 50% 50%;
    background-size: rem(10) auto;
    transform: rotate(270deg);
  }

  &-footer {
    margin-bottom: 0;

    @include media-breakpoint-up(sm, $grid-breakpoints) {
      position: absolute;
      right: rem(10);
      bottom: 0;
      margin: 0;
    }

    @include media-breakpoint-up(lg, $grid-breakpoints) {
      // Leave as px
      right: 15px;
    }

    .to-top-link {
      width: rem(65);
      height: rem(35);
      background-image: $uks-svg-arrow-thin;
      background-size: cover;
      transform: none;
    }
  }
}
