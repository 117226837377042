.breadcrumb {
  font-size: rem(11);
  line-height: (14 / 11);
  white-space: nowrap;

  &--main {
    padding-top: 8px;
  }

  &--faculty {
    display: block;
    min-height: 26px;
    padding-top: 4px;
    margin: 0;
    font-size: rem(13);
    line-height: (22 / 13);
  }

  &-wrapper {
    margin-bottom: 22px;
  }

  &-item {
    margin-bottom: 0;

    + .breadcrumb-item {
      padding-left: 0;
    }

    // Switching default ::befores to ::afters to make line-breaking in new small container look a bit better. Unclear if keeping this, so adding as an overwrite:

    + .breadcrumb-item::before {
      display: none;
    }

    &::after {
      display: inline-block;
      padding-right: $breadcrumb-item-padding;
      padding-left: $breadcrumb-item-padding;
      color: $breadcrumb-divider-color;
      content: "#{$breadcrumb-divider}";
    }

    // IE11 hack - see Bootstrap's _breadcrumb.scss for more info
    /* stylelint-disable no-duplicate-selectors  */
    &:hover::after {
      text-decoration: underline;
    }
    &:hover::after {
      text-decoration: none;
    }
    /* stylelint-enable  */

    &:last-child {
      &::after {
        display: none;
      }
    }

    // Gets messy due to requirement to use rcrumbs
    &--dynamic,
    &--faculty {
      display: none;
      overflow: hidden;

      &::after {
        display: none;
      }

      &.show {
        /* stylelint-disable */
        display: inline-block !important;
        /* stylelint-enable */

      }

      &.ellipsis {
        text-overflow: ellipsis;
      }

    }

  }

  // Only present in main breadcrumb, not faculty breadcrumb
  &-link {
    display: inline-block;
    color: $uks-color-tones-21;
    vertical-align: middle;

    &:visited {
      color: $uks-color-tones-21;
    }
  }

  // Gets messy due to requirement to use rcrumbs - do not need this element otherwise. rcrumbs mis-measures when using standard ::before/::after
  &-divider {
    display: inline-block;
    width: ($breadcrumb-item-padding * 2) + rem(3);
    height: rem(14);
    padding: 0 $breadcrumb-item-padding;
    vertical-align: middle;
    background: $uks-svg-link-internal-dark no-repeat 50% 50%;
    background-size: auto rem(8);

    .breadcrumb--faculty & {
      background-size: auto rem(10);
    }
  }
}
