.carousel {
  @include media-breakpoint-down(md) {
    padding-bottom: 30px;
  }

  &-item {
    align-items: center;
  }

  &-caption {
    // Have to overwrite some heavy-handed Bootstrap defaults
    right: auto;
    bottom: 30px;
    left: 50%;
    width: 540px;
    // Allow space for controls:
    max-width: 66%;
    transform: translateX(-50%);

    // Workaround
    @include media-breakpoint-down(md) {
      main .wall__item &,
      main .row & {
        bottom: 0;
      }
    }

  }

  &-caption-link {
    color: $white;
    background-color: $uks-color-tones-14;
    border: none;
    .carousel-caption-text + & {
      margin-top: rem(4);
    }
  }

  &-caption-text {
    // Avoid rems, limited space
    padding: 16px 20px;
    background: rgba($uks-color-tones-2, .95);

    @include media-breakpoint-down(md) {
      main .wall__item &,
      main .row & {
        padding: 8px;
        font-size: rem(14);
        line-height: (18 / 14);
      }
    }

    @include media-breakpoint-down(uksxs, $uks-altgrid-breakpoints) {
      main .wall__item &,
      main .row & {
        font-size: rem(12);
        line-height: (14 / 12);
      }
    }
  }

  &-caption-headline {
    color: $black;
  }

  &-caption-headline-link {
    color: inherit;

    &::after {
      display: inline-block;
      width: rem(12);
      height: rem(12);
      margin-left: rem(6);
      vertical-align: middle;
      content: "";
      background: $uks-svg-arrow-thick no-repeat 50% 50%;
      background-size: auto 100%;
      transform: translateY(rem(-1));
    }
  }

  &-caption-headline-sub {
    margin-bottom: rem(8);
    font-size: rem(20);
    font-weight: 700;
    line-height: (24 / 20);
    // color: $uks-color-primary;
    transform: translateY(rem(-4));

    @include media-breakpoint-down(md) {
      main .wall__item &,
      main .row & {
        margin-bottom: 0;
        font-size: rem(16);
        line-height: (20 / 16);
      }
    }

    @include media-breakpoint-down(uksxs, $uks-altgrid-breakpoints) {
      main .wall__item &,
      main .row & {
        font-size: rem(14);
        line-height: (16 / 14);
      }
    }
  }

  &-caption-detail {
    margin-bottom: 0;
  }

  &-control-prev-icon,
  &-control-next-icon {
    // Assigned as $carousel-control-icon-width in Bootstrap, so have to override
    height: $carousel-control-icon-height;
    background-size: cover;
    transform: rotate(90deg);
  }

  &-control-prev-icon {
    transform: rotate(270deg);
  }

  &-control-next,
  &-control-prev {
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, .35));

    &:focus {
      outline: 1px dotted $uks-color-tones-14;
    }

    .carousel--with-clickenlarge & {
      top: 50%;
      bottom: auto;
      transform: translateY(-50%);
    }
  }

  &-indicators {
    right: auto;
    left: 50%;
    max-width: 70%;
    padding: 5px 7px;
    margin: 0;
    background-color: rgba($white, .85);
    transform: translateX(-50%);

    @include media-breakpoint-down(md) {
      bottom: 0;
      background-color: transparent;
    }

    @include media-breakpoint-up(md) {
      padding: 7px;
    }

    li {
      width: $carousel-indicator-width;
      margin-bottom: 0;
      background-color: transparent;
      border: 1px solid $uks-color-primary;
      border-radius: 100%;
    }

    .active {
      background-color: $uks-color-primary;
    }

    .carousel-pause {
      // Specificity
      &.carousel-pause {
        position: relative;
        margin-right: 4px;
        margin-left: 16px;
        text-indent: 0;
        background: none;
        border: 0;
        border-radius: 0;
      }

    }

    .carousel-pause-control {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 0;
      cursor: pointer;
      background: none;
      border: 0;

      &::before {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        content: "";
        background: $uks-svg-pause no-repeat 50% 50%;
        background-size: auto 100%;
      }

      &.carousel-pause-control--paused {
        &::before {
          background-image: $uks-svg-play;
        }
      }

    }
  }

  .figure-img {
    display: block;
  }

  // Homepage full-width carousel:
  &-stretched {
    // 1px horizontal overflow at smaller screen sizes. Seems to be Bootstrap bug. Workaround:
    max-width: 1920px;
    padding-bottom: 30px;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }

    .carousel-inner {
      // Smallscreen behaviour complex, us a -down query to avoid too many overwrites, step out from Bootstrap - too restrictive
      @media (max-width: $uks-min-width-carousel) {
        width: 100%;
        height: 240px;
      }

    }

    .carousel-item {
      // Smallscreen behaviour complex, us a -down query to avoid too many overwrites, step out from Bootstrap - too restrictive
      @media (max-width: $uks-min-width-carousel) {
        position: absolute;
        width: 100%;
        height: 240px;
        overflow: hidden;
      }
    }

    .carousel-caption {
      top: auto;
      bottom: 20px;
      width: calc(100% - 40px);
      max-width: none;

      // Smallscreen behaviour complex, use a max-width query to avoid too many overwrites, step out from Bootstrap - too restrictive
      @media (max-width: $uks-min-width-carousel) {
        padding: 0;
      }

      @include media-breakpoint-up(md) {
        top: auto;
        bottom: 0;
        left: rem(60);
        width: 415px;
        max-width: calc(100% - #{rem(120)});
        text-align: left;
        transform: none;
      }

      @include media-breakpoint-up(lg) {
        left: rem(110);
        width: 570px;
        max-width: calc(100% - #{rem(220)});
      }
    }

    .carousel-caption-text {
      margin-right: auto;
      margin-bottom: rem(8);
      margin-left: auto;
      background-color: rgba($white, .85);

      // Smallscreen behaviour complex, use a max-width query to avoid too many overwrites
      @include media-breakpoint-down(md) {
        padding: 8px 12px 12px;
      }

      @include media-breakpoint-up(md) {
        margin-right: 0;
        margin-bottom: rem(12);
        margin-left: 0;
      }
    }

    .carousel-caption-link {
      padding-top: rem(6);
      padding-bottom: rem(6);
      margin-top: 0;
      font-size: rem(14);
      font-weight: 400;

      @include media-breakpoint-up(lg) {
        font-size: rem(17);
      }
    }

    .carousel-caption-headline-main {
      display: block;
      margin-bottom: rem(8);
      font-family: $font-family;
      font-size: rem(17);
      font-weight: 700;
      line-height: 1.1;
      color: $black;

      @include media-breakpoint-up(md) {
        font-size: rem(20);
      }

      @include media-breakpoint-up(lg) {
        font-size: rem(36);
      }

    }

    .carousel-caption-headline-link {
      &::after {
        width: rem(12);
        height: rem(12);

        @include media-breakpoint-up(md) {
          width: rem(16);
          height: rem(16);
        }

        @include media-breakpoint-up(lg) {
          width: rem(22);
          height: rem(22);
        }
      }
    }

    .carousel-caption-detail {
      font-size: rem(14);
      color: $black;

      @include media-breakpoint-up(lg) {
        font-size: rem(18);
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: rem(50);

      @include media-breakpoint-down(sm) {
        position: absolute;
        top: auto;
        bottom: 30px;
        display: block;
        width: rem(50);
        height: rem(50);
        opacity: .75;
        transform: none;

        &-icon {
          width: rem(30);
          height: rem(30);
          margin: rem(10);
        }
      }

      @include media-breakpoint-up(md) {
        display: flex;
      }

      @include media-breakpoint-up(lg) {
        width: rem(100);
      }
    }

    .carousel-indicators {

      @include media-breakpoint-up(md) {
        right: rem(50);
        bottom: 20px;
        left: auto;
        background-color: rgba($white, .85);
        transform: none;
      }

      @include media-breakpoint-up(lg) {
        right: rem(100);
      }

      li {
        //width: 10px;
        //height: 10px;
        //margin: 0 4px 6px;

        &::before,
        &::after {
          display: none;
        }
      }

    }

    .js-clickenlarge {
      @include media-breakpoint-down(sm) {
        &::after {
          display: none;
        }
      }
    }

    .figure-img {
      width: 100%;

      // Smallscreen behaviour complex, use a max-width query to avoid too many overwrites, step out from Bootstrap - too restrictive
      @media (max-width: $uks-min-width-carousel) {
        position: absolute;
        top: 0;
        left: 50%;
        width: auto;
        max-width: none;
        height: 240px;
        transform: translateX(-50%);
      }
    }
  }


}
