.alphabet {
  padding: 0;
  margin: 0 0 0 rem(-15);
  font-size: rem(20);
  line-height: (22 / 20);
  color: $uks-color-tones-6;
  list-style: none;

  &-wrapper {
    margin-bottom: rem(22);
  }

  &-item {
    display: inline-block;
    margin: 0 rem(15) rem(22);
  }
}
