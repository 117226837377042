// UKS-specific styles
.headline {
  // Typically used on headline links in lists
  &-link {
    &::after {
      display: inline-block;
      width: rem(13);
      height: rem(14);
      margin: rem(-2) 0 0 rem(6);
      vertical-align: middle;
      content: "";
      background: $uks-svg-link-internal no-repeat 50% 50%;
      background-size: contain;

      .card-teaser-colored &,
      .news-list-teaser-colored & {
        filter: grayscale(1);
      }

      .card-topnews.card-primary & {
        background-image: $uks-svg-link-internal-white;
      }
    }

    &--external {
      &::after {
        background-image: $uks-svg-link-external;
      }
    }

    &--inverted {
      color: $black;
      text-decoration-color: $black;

      &:hover {
        color: $black;
      }
    }
  }
  &-link-dark {
    &::after {
      background: $uks-svg-link-internal-dark no-repeat 50% 50%;
    }
  }
}
