.form {
  &-login {
    margin-bottom: rem(40);

    .content-text + & {
      margin-top: rem(-10);
    }
  }

  &-control {
    // Reset untouchable Bootstrap style
    border: 0;

    &:not(select) {
      border-bottom: $uks-border-width solid $uks-color-secondary;
    }

    // Overwrite Bootstrap
    &:focus {
      outline: 1px solid $input-bg;
    }

  }

  &-control-label-block {
    display: block;
  }

  &-check-label {
    font-size: 1rem;
    font-weight: 400;
  }

  &-check-input {
    &:only-child {
      // Overwrite Bootstrap odd default. May be temp if we wrap our select label text for styling
      position: absolute;
    }
  }

  &-legend-label {
    margin-bottom: rem(5);
    font-family: $font-family-alt;
    font-size: rem(14);
    font-weight: 700;
    line-height: (22 / 14);
  }

  // Workaround for Powermail flexibility
  &-mail {
    .btn {
      margin-right: $spacer;
      margin-bottom: $form-group-margin-bottom;
      vertical-align: top;
    }
  }
}

/* stylelint-disable selector-no-qualifying-type  */
select.form-control {
  &:focus {
    background-color: $input-bg;
  }

  @supports (appearance: none) {
    &:not([multiple]) {
      padding-right: rem(60);
      background-image: $uks-svg-arrow-thick-down;
      background-repeat: no-repeat;
      background-position: calc(100% - #{rem(15)}) calc(50% + #{rem(1)});
      appearance: none;
    }
  }

  &--search-select {
    font-weight: 700;
    color: $uks-color-primary;
  }
}
/* stylelint-enable  */

.powermail_message_error,
.powermail-errors-list {
  color: $danger;
}
