////
/// @author Ian Goodger
/// @group General
////

/// Applies cross-browser word-breaking, using hyphens where supported, otherwise overflow-wrap / word-wrap
///
/// Complex due to way hyphens-supporting browsers can handle word-break. Needs to be one or the other, not both
/// @param {String} $direction [add] add or remove word breaking
/// @output Cross-browser hyphens / word-wrap declarations
/// @example scss
///   .foo {
///       @include hyphens;
///   }
@mixin hyphens($direction: add) {
  @if ($direction == remove) {
    word-break: normal;
    word-wrap: normal;

    // Needed to match original specificity
    @supports (hyphens: auto) {
      hyphens: manual;
    }

  } @else {
    word-wrap: break-word;
    overflow-wrap: break-word;

    @supports (hyphens: auto) {
      hyphens: auto;
    }

  }
}

/// Removes all styling from a <button>, displays as regular clickable text
/// @output reset-to-text declarations
/// @example scss
///   .foo {
///       @include text-button;
///   }
@mixin text-button() {
  margin: 0;
  text-align: left;
  cursor: pointer;
  background: none;
  border: 0;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }
}

/// Applies a project-standard opacity transition
///
/// Helper mixin to ensure opacity transitions are consistent site-wide. Generally used for UX hover / fade effects etc.
///
/// Params are deliberately limited - too many options would defeat the object of the mixin. Generally, it should be used without passing any params at all.
/// @param {Duration + unit} $transition-duration [$uks-duration-short] Duration of transition
/// @require $uks-duration-short
/// @output transition declaration
/// @example
///   .foo {
///       @include opacity-transition;
///   }
@mixin opacity-transition($transition-duration: $uks-duration-short) {
  transition: opacity $transition-duration ease;
}

/// Make images (and objects) responsive.
///
/// Assumes IMG tag has a height attribute which needs overriding.
/// @output CSS declarations to prevent widths > 100% and to keep correct proportions.
/// @example scss
///   .foo {
///       @include responsive-img;
///   }
@mixin responsive-img() {
  max-width: 100%;
  /* stylelint-disable declaration-no-important */
  height: auto !important;
  /* stylelint-enable declaration-no-important */
}

/// Legend keys for graphs - not always part of Chartist, so make available as mixin
///
/// @output defs for legend keys
/// @require rem
/// @example scss
///   .foo {
///       @include prev-next-icon;
///   }
@mixin graph-legend-keys() {
  display: inline-block;
  width: rem(20);
  height: rem(20);
  margin-right: 16px;
  vertical-align: top;
}
