//$timeline-layout-switch: $uks-color-border;

.timeline {
  padding-left: 0;
  margin: 0;
  list-style: none;

  &__item {
    margin-bottom: rem(30);

    &:last-child {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  &__header {
    margin-bottom: rem(28);

    @include media-breakpoint-up(sm) {
      display: flex;
      flex: 0 0 rem(167);
      flex-direction: column;
      padding-right: rem(45);
      text-align: right;
      border-right: 3px solid $uks-color-primary;
    }
  }

  &__headline {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
      display: block;
      flex: 0 0 auto;
    }
  }

  &__subheadline {
    margin: rem(30) 0 0;

    @include media-breakpoint-up(sm) {
      flex: 0 0 auto;
    }
  }

  &__counter {
    flex: 0 0 auto;
    min-width: rem(45);
    padding-right: rem(2);
    font-size: rem(54);
    font-weight: 400;
    line-height: rem(45);
    color: $uks-color-primary;
    border-right: 3px solid $uks-color-primary;

    @include media-breakpoint-up(sm) {
      display: block;
      padding-right: 0;
      margin-bottom: rem(16);
      border: 0;
    }
  }

  &__headline-text {
    flex: 1 1 auto;
    padding-left: rem(18);

    @include media-breakpoint-up(sm) {
      padding-left: 0;
    }
  }

  &__content {
    color: $black;

    @include media-breakpoint-up(sm) {
      flex: 1 1 auto;
      padding-left: rem(42);
    }

    // RTE content, so no classes available
    strong {
      color: $uks-color-text;
    }

    ul {
      padding: 0;
      list-style: none;

      li {
        position: relative;
        padding-left: rem(32);

        &::before {
          position: absolute;
          top: rem(5);
          left: 0;
          width: rem(15);
          height: rem(15);
          content: "";
          border: 1px solid $black;
        }
      }
    }
  }

  &__image-content {
    position: relative;
    margin-bottom: rem(28);

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__main-content {
    margin-bottom: rem(32);

    > *:last-child {
      margin-bottom: 0;
    }

    .btn {
      color: $white;
      background-color: $uks-color-primary;
      border-color: $uks-color-primary;

      &:hover,
      &:focus {
        color: $uks-color-primary;
        background-color: transparent;
      }
    }
  }

  &__additional-content {
    padding: rem(18) rem(20) rem(20);
    margin-bottom: rem(32);
    background: $uks-color-tones-17;

    > *:last-child {
      margin-bottom: 0;
    }

    a {
      color: $uks-color-primary-dark;
    }

    .btn-danger {
      color: $white;
    }
  }

  &__signoff {
    margin-bottom: 0;
    color: $black;

    &--main {
      @include media-breakpoint-up(sm) {
        // For semantics, hide it but keep it sr-visible
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
        clip-path: inset(50%);
      }
    }

    &--aside {
      display: none;

      @include media-breakpoint-up(sm) {
        // Visible, but aria-hidden to preserve sr semantics
        display: flex;
        flex: 1 1 auto;
        align-items: flex-end;
      }
    }
  }

}
