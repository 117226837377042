// Non-Bootstrap element.
// May need to be created in RTE, so using RTE-friendly link classes. May need to work outside linklists
.linklist {
  padding: $uks-teaser-spacer-top $uks-teaser-spacer-right $uks-teaser-spacer-bottom $uks-teaser-spacer-left;
  margin: 0;
  list-style: none;
  background: $white;
  border: 1px solid $uks-color-border;

  // Special case - linklist in Newswall. Need to make it full height
  .wall__item & {
    flex: 1 0 auto;
  }

  &-plain,
  .card & {
    padding: 0;
    background: none;
    border: 0;
  }

  // Special case - linklist in accordion (as inserted record)
  .card [role="tabpanel"] & {
    padding: $uks-teaser-spacer-top $uks-teaser-spacer-right $uks-teaser-spacer-bottom $uks-teaser-spacer-left;
    background: $white;
    border: 1px solid $uks-color-border;
  }

  &-wrapper {
    // Special case - linklist in Newswall. Need to make it full height
    .wall__item & {
      display: flex;
      flex-direction: column;
    }
  }

  // Have to leave both options. May change when clear how and when this will be required
  &-wrapper > .h2:not(.sr-only),
  &-header {
    position: relative;
    padding: $uks-teaser-spacer-top-reduced $uks-teaser-spacer-right rem(12) $uks-teaser-spacer-left;
    margin-bottom: 0;
    background: $white;
    border-color: $uks-color-border;
    border-style: solid;
    border-width: 1px 1px 0;

    &--internal {
      padding-left: 0;
    }

    ~ .jumpblock-wrapper .linklist,
    ~ .linklist {
      padding-top: 0;
      border-top: 0;
    }
  }

  &-jumpblock,
  &-jumpblock--lightblue {
    // Additional specificity:
    .h2.h2,
    .linklist {
      background-color: $uks-color-tones-17;
      border-color: $uks-color-tones-17;
    }

    .h2.h2 {
      &::after {
        background: $uks-color-text;
      }
    }
  }

  &-jumpblock--nocolor {
    // Additional specificity:
    .h2.h2,
    .linklist {
      background-color: transparent;
      border-color: transparent;
    }


    .h2.h2 {
      &::after {
        background: $uks-color-text;
      }
    }
  }

  &-jumpblock--lightgreen {
    // Additional specificity:
    .h2.h2,
    .linklist {
      background-color: $uks-color-tones-18;
      border-color: $uks-color-tones-18;
    }


    .h2.h2 {
      &::after {
        background: $uks-color-text;
      }
    }
  }


  &-description {
    display: block;
    padding: 0 $uks-teaser-spacer-right rem(10) $uks-teaser-spacer-left;
    margin: 0;
    border-color: $uks-color-border;
    border-style: solid;
    border-width: 0 1px;

    // Description can either preface the list (if so, needs borders), or can describe an individual link (if so, no borders)
    .linklist & {
      border: 0;
    }
  }

  ul {
    padding-left: rem(26);
    list-style: none;
  }
}

.linklist,
.icon-links {
  // All link classes, assuming that it's impractical to add more than one
  [class^="link-"] {
    position: relative;
    display: inline-block;
    max-width: 100%;
    padding-left: rem(26);
    color: $black;

    &::before {
      position: absolute;
      top: rem(6);
      left: 0;
      width: rem(13);
      height: rem(14);
      content: "";
      background: no-repeat 50% 50%;
      background-size: contain;
    }

    &.link-local::before,
    &.link-internal::before {
      background-image: $uks-svg-link-internal;

      .linklist-jumpblock &,
      .card-teaser--filled & {
        filter: grayscale(1);
      }
    }

    &.link-internal::before {
      top: rem(5);

      .page-footer & {
        top: rem(3);
      }
    }

    &.link-external::before {
      height: rem(13);
      background-image: $uks-svg-link-external;

      .card-teaser--filled & {
        filter: grayscale(1);
      }
    }

    &.link-local::before {
      transform: rotate(90deg);
    }

    &.link-download::before {
      top: rem(4);
      width: rem(17);
      height: rem(15);
      background-image: $uks-svg-link-download;
      transform: rotate(90deg);

      .card-teaser--filled & {
        filter: grayscale(1);
      }
    }
  }
}

.card-list {
  // All link classes, assuming that it's impractical to add more than one
  [class^="link-"] {
    margin-top: rem(8);

    .card-teaser-event & {
      margin-top: 0;
    }
  }
}
