.modal {
  &-title {
    font-family: $font-family;
    font-size: rem(18);
    font-weight: 700;
    line-height: (26 / 18);
  }

  &-footer {
    padding-top: 20px;
  }
}
