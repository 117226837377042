.pretty-numbers {
  color: $black;

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    flex: 0 0 auto;
    width: 100%;

    @include respond-up-from($uks-screen-medium-2) {
      width: (100% / 3);

      &--50 {
        width: 50%;
      }

      &--66 {
        width: (100% / 3 * 2);
      }
    }
  }

  &__item {
    height: 100%;
    background: $uks-color-tones-18;

    &--color-yellowgreen {
      background: $uks-color-tones-19-alt;
    }

    &--color-lightblue {
      background: $uks-color-tones-13;
    }

    &--image {
      // Can be overwritten inline
      background: $black;

      @include respond-up-from($uks-screen-medium-2) {
        display: flex;
        align-items: center;
      }
    }

    &--text {
      padding: rem(32) rem(30) rem(36);
    }
  }

  &__title {
    margin: 0;
    font-size: rem(22);
    font-weight: 700;
    line-height: (26 / 22);
  }

  &__text {
    margin: 0;

    .pretty-numbers__title + & {
      margin-top: rem(9);
    }
  }

  &__link {
    margin-top: rem(16);
  }

  &__text-large {
    margin: 0;
    font-family: $font-family-alt-medium;
    font-size: rem(17);
    line-height: (24 / 17);
  }

  // stylelint-disable property-no-unknown
  &__image {
    max-width: 100%;
    height: auto;

    @include respond-up-from($uks-screen-medium-2) {
      .pretty-numbers__col--33 & {
        object-fit: cover;
        aspect-ratio: 1 / 1;
      }
    }
  }
  // stylelint-enable property-no-unknown

  &__image-wrapper {
    position: relative;
  }
}
