.post {
  margin-bottom: $uks-component-margin;
  font-family: $font-family-alt;

  &__title-box {
    display: flex;
    flex-direction: column-reverse;
  }

  &__title {
    margin-bottom: rem(12);
  }

  &__meta {
    margin-bottom: rem(5);
    font-size: rem(20);
    line-height: (23 / 20);
    text-transform: uppercase;
  }

  &__description {
    font-size: rem(20);
    line-height: (30 / 20);
  }

  &__tags {
    margin-top: rem(17);
  }

  &__tags-list {
    padding: 0;
    margin: 0;
    line-height: (19 / 16);
    list-style: none;
  }

  &__tags-item {
    display: inline-block;
    margin-right: rem(12);

    &:last-child {
      margin-right: 0;
    }
  }
}
