// Overwrite Bootstrap. Over-complex to avoid breaking Bootstrap modifier classes
.table {
  thead th {
    padding-top: rem(16);
    border-top: 0;
    border-bottom: 0;
  }

  /* stylelint-disable selector-max-compound-selectors, selector-max-type  */
  // Not ideal...
  thead:not(.thead-inverse) + tbody > tr:first-child {
    th,
    td {
      border-top-color: $uks-color-secondary;
    }
  }

  // Not ideal...
  &.table-striped tbody:first-child > tr:first-child {
    th,
    td {
      border-top-width: 0;
    }
  }
  /* stylelint-enable  */

  // Bootstrap responsive tables cannot be used - behave badly on wider screens (table -> display: block)
  // Basic workaround to stop tables breaking layout on small screens:
  &-wrapper {
    width: 100%;
    overflow: auto;

    @include media-breakpoint-down(xs) {
      // Best guess at a webkit touch device - add smooth scrolling
      // May need to remove this if full backwards compatibility required
      @supports (-webkit-overflow-scrolling: touch) {
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
      }
    }
  }
}
