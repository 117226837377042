.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: rem(12);
  padding: 0;
  margin: 0;
  list-style: none;

  @include respond-up-from($uks-screen-medium) {
    gap: rem(20);
  }

  &__item {
    display: block;
    flex: 0 0 auto;
    width: calc(#{100% / 2} - #{rem(12) / 2});
    margin: 0;

    @include respond-up-from($uks-screen-medium) {
      width: calc(#{100% / 3} - #{rem(40) / 3});

      .start-page-faculty &,
      .start-page & {
        width: calc(#{100% / 4} - #{rem(60) / 4});
      }
    }
  }

}
